@import "../../scss/variables.scss";
@import "../../scss/fonts";
@import "../../scss/mixins";

.list_bl {
  &:focus {
    outline-style: none;
    outline-width: 0px !important;
    outline-color: none !important;
  }
}

.title_category {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &__text {
    font-size: 18px;
    font-family: "GolosText-Medium";
    font-weight: normal;
    margin: 16px 0 16px 0;
    color: $color_text_b;
  }
  &__img {
    height: 18px;
    margin-right: 8px;
    vertical-align: sub;
  }
  // .group_buttons {
  //   > button {
  //     background: none;
  //     cursor: pointer;
  //     border: 0;
  //     padding: 0;
  //     line-height: 0;
  //     height: 20px;
  //     //width: 20px;
  //     color: #A8A8A8;
  //     text-decoration: underline;
  //     margin: 0 4px;
  //     &.active_but {
  //       background: $yellow_color;
  //       @include border-radius(50%);
  //       color: #000;
  //       svg {
  //         height: 13px;
  //         width: 13px;
  //         fill: #000;
  //         .color {
  //           fill: #000;
  //         }
  //         .color_pen {
  //           fill: #ffffff;
  //           box-shadow: 1px 1px 1px #000;
  //           filter: drop-shadow(0px 1px 1px #737373);
  //         }
  //       }
  //     }
  //   }
  // }
  &__svg {
    height: 16px;
    width: 16px;
    fill: $color_new;
    .color {
      fill: $color_new;
    }
  }
}

.btn_star {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    padding: 0;
    height: auto;
    width: auto;
    transition: none;
    border: 0;
    margin: 0;
    line-height: normal;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    svg {
      font-size: 24px;
    }
    &:hover,
    &:focus {
      box-shadow: none;
      content: "";
      outline: none;
      border: 0 !important;
      border-color: transparent !important;
      background: transparent;
      text-decoration: none;
    }
  }
  :global(.ant-btn) {
    border: 0 !important;
  }
}
.ticker_desc {
  font-size: 14px;
  color: $gray_text;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ticker_desc1 {
  font-size: 16px;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: "Roboto-Medium";
}

.pm_ticker_item {
  border-bottom: 1px solid $border_color;
  padding-bottom: 0;
  height: auto !important;
  padding: 16px 0;
}
.col_width {
  width: calc(100% - 116px);
}
.flex_bl {
  display: flex;
  align-items: center;
  flex-direction: row;
  input {
    margin-left: 16px;
    box-sizing: border-box;
    width: calc(100% - 56px);
  }
}
.add_but,
.but_edit {
  svg {
    //filter: drop-shadow(0px 0px 4px #bca6ff);
  }
}
.color_pen {
  fill: #bca6ff;
}
.titl_h3 {
  font-weight: normal;
  margin-top: 16px;
  margin-bottom: 16px;
}

.tabsfav {
  a {
    background: transparent !important;
    border-radius: 0 !important;
    &::before {
      content: none;
    }
  }
}

.fonfree {
  background: #f5f6f7 !important;
}
.flex_modal {
  display: flex;
  align-items: center;
  &__title {
    color: $color_text_b;
    font-family: "GolosText-Medium";
    font-size: 18px;
  }
  &__text {
    span {
      font-family: "GolosText-Medium";
      font-size: 13px;
      display: block;
    }
    .text_one {
      font-size: 14px;
      color: $color_text_gr;
      font-family: "GolosText";
    }
  }
}
