.ui-icon-button {
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100px;
    border: 1px solid var(--basic-dark);

    .main-icon {
        path {
            fill: var(--basic-dark);
        }
    }

    &--clear {
        border: none;
    }

    &--contained {
        background-color: var(--basic-dark);

        .main-icon {
            path {
                fill: white;
            }
        }
    }

    &--primary {
        background-color: var(--basic-accent);
        border-color: var(--basic-accent);

        .main-icon {
            path {
                fill: white;
            }
        }
    }

    &--size_sm {
        width: 30px;
        height: 30px;

        .main-icon {
            width: 18px;
            height: 18px;
        }
    }
}