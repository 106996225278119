$green: var(--misk-success);
$red: var(--misk-error);
$color_text_b: #221f1c;
$color_text_gr: #a8a8a8;
$color_text_gr1: #7c7c7c;
$color_text_gr2: #7e7e7e;
$yellow_color: #bca6ff;
$button_yellow: rgba(230, 248, 0, 0.12);
$yellow_opacity: rgba(230, 248, 0, 0.08);
$background_site: #0a0a0a;
$background_block: #141519;
$border_color: #2e2e2e;
$gray_text: #7a7a7a;
$color_text: rgba(99, 45, 45, 0.7);
$white_text: #ffffff;
$black: #000000;
$blue_link: #007aff;
$border_logo: #545860;
$block_bg: #f5f6f7;
$gradient: linear-gradient(180deg, #bca6ff 11.46%, #76c2f9 72.4%, #0febef 100%);
$gradient1: linear-gradient(
  180deg,
  #0febef 11.46%,
  #76c2f9 72.4%,
  #bca6ff 100%
);
$color_new: #bca6ff;

:root {
  --basic-light: #ffffff;
  --basic-light2: #E3E6E8;

  --secondary-light: #f5f5f5;

  --basic-dark: #3d3d3e;
  --basic-dark10: #eaeaea;
  --basic-dark20: #E4E5E6;
  --basic-dark40: #a8a8a8;
  --basic-dark60: rgba(0, 0, 0, 0.6);

  --misk-success: #45ca5b;
  --misk-error: #fb5e49;
  --misk-alert: #d5b610;
  --basic-accent: #7a24ea;

  --vh: 1vh; // Overriden in App.jsx
}
