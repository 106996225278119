.helper-text {
    position: relative;
    width: fit-content;
    font-size: 14px;
    line-height: 20px;
    color: var(--basic-dark40);
    vertical-align: text-top;

    cursor: pointer;

    svg {
        position: absolute;
        left: 0;
        bottom: -1px;

        width: 100%;
        height: auto;

        path {
            stroke-dashoffset: 0;
            transition: 0.4s ease;
        }
    }

    &:hover {
        path {
            stroke-dashoffset: 10;
        }
    }
}

.helper-modal {

    &-title {
        margin-top: 20px;
        padding: 0 15px;
        font-size: 18px;
        font-weight: 500;
        color: var(--basic-dark);
    }
    &-content {
        margin-top: 15px;
        padding: 0 15px;

        font-size: 14px;
        line-height: 1.5;
    }
    
    &-btn {
        margin: 0 15px;
        margin-top: 20px;
        margin-bottom: 30px;
        width: calc(100% - 30px);
    }
}