.fixedImg {
    position: sticky;
    top: 54px;
    left: 0;
    right: 0;
    z-index: 1;
}
.newsWrap {
    position: relative;
    overflow: hidden;
    z-index: 2;
    padding-top: 15px;
    background-color: var(--secondary-light);
}
