.block {
  //width: calc(100% + 30px);
  height: 200px;
  border-radius: 30px;
  overflow: hidden;
  background-color: #753bbd3b;
  background-image: url('../../assets/images/start_verification.svg');
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 24px 0;
  padding: 0 32px;
  @media (max-width: 400px) {
    padding: 0 24px;
  }

  video {
    pointer-events: none;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
    transform: scale(1.01);
  }
  h1 {
    color: var(--basic-dark);
    font-family: 'GolosText-Bold';
    font-size: 18px;
  }
  p {
    color: var(--basic-dark);
    font-family: 'GolosText-Medium';
    font-size: 18px;
  }
}

.btn {
  background-color: #221f1c;
  font-family: 'GolosText-Medium';
  padding: 12px 24px;
  white-space: nowrap;
  margin-top: 16px;
}
