.title {
  text-align: center;
  font-family: "GolosText";
  color: var(--basic-dark);
  font-weight: 500;
}
.border_gg {
  button {
    border: 1px solid rgba(37, 37, 37, 0.6);
  }
}
.loader {
  margin: 80px auto;
  width: 100%;
}
.list {
  overflow-y: auto;
  height: calc(100dvh - 80px);
}

.item {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    color: var(--basic-dark);
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  border-bottom: 1px solid var(--basic-dark10);

  :global(.main-icon) {
    width: 20px;
    height: 20px;
  }
}
.itemIcon {
  border: 1px solid rgba(37, 37, 37, 0.6);
  height: 38px;
  text-align: center;
  box-sizing: border-box;
  padding: 8px;
  width: 38px;
  border-radius: 100px;
  margin-left: 16px;
}
