@import "../../scss/variables.scss";
@import "../../scss/mixins";

.ticker_popular {
  display: block;
  background: $color_text_b;
  padding: 4px 4px 8px 4px;
  box-sizing: border-box;
  border-radius: 35px;
  &__date {
    background: #fff;
    margin-right: 0;
    border-radius: 30px;
    padding: 8px;
    align-items: center;
    display: flex;
    box-sizing: border-box;
    p {
      font-size: 18px;
      margin-left: 8px;
      color: $color_text_b;
      font-family: "GolosText-Medium";
    }
    :global(.image-preview) {
      border: 1px solid #e4e5e6;
    }
  }
  &__price {
    text-align: center;
    p {
      font-size: 14px;
      color: #fff;
      font-family: "GolosText";
      span {
        font-family: "GolosText";
      }
    }
    span {
      font-family: "GolosText-Medium";
      font-size: 13px;
    }
  }
}
