.ticker-card {
  position: relative;
  width: 100%;

  &-wrap {
    width: 100%;
    padding: 15px;
    border-radius: 20px;

    border: 1px solid var(--basic-dark10);
    background-color: var(--basic-light);

    display: flex;
    align-items: center;
  }

  .ticker-title {
    flex: 1;
    margin-right: 10px;
  }

  &-price {
    .price-changing {
      transform: translateX(2px);
    }
  }
  .ticker-card-favorite {
    position: absolute;
    right: 20px;
    top: 15px;
    z-index: 1;
    button {
      .main-icon {
        width: 40px;
      }
    }
    &.active {
      background: #221f1c;
      width: 16px;
      border-radius: 50px;
      height: 16px;
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 1;
      button {
        width: 16px;
      }
      .minus_bl {
        svg {
          path {
            fill: #ffffff;
          }
        }
      }
    }
  }

  .ticker-card-button {
    position: absolute;
    top: 15px;
    right: 20px;
    border-color: var(--basic-dark60);

    .main-icon {
      path {
        fill: var(--basic-dark60);
      }
    }
    button {
      border: 0;
    }
  }

  &-favorite {
    position: relative;

    .ui-icon-button .main-icon {
      width: 20px;

      transition: 0.4s ease;
    }

    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);

      transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    }

    &.active {
      .ticker-card-favorite-icon {
        transform: translate(-50%, -50%) scale(1);
      }

      .ui-icon-button .main-icon {
        transform: scale(0) !important;
      }
    }
  }

  &--withTrading {
    .ticker-card-wrap {
      padding-right: 70px;
    }
  }
}

.minus_bl {
}
