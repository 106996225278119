.ui-clickable {
  width: 100%;
  color: var(--basic-dark);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.4s ease;
  // border-radius: inherit;

  &:active:not(:disabled) {
    transform: scale(0.98);
  }

  &-ripple {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: left top;
    opacity: 0;
    z-index: 2;

    pointer-events: none;
    user-select: none;

    div {
      width: 50px;
      height: 50px;

      border-radius: 100%;
      background-color: black;
      filter: blur(10px);
      transform: translate3d(0, 0, 0);
    }
  }

  &:disabled {
    cursor: auto;
    background-color: var(--basic-dark10) !important;
    color: #d3d3d3 !important;
    //filter: saturate(0) brightness(2.5);
  }
}
