.main-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    width: inherit;
    height: inherit;
  }

  font-size: 16px;
  font-family: monospace;
}
