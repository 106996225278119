.broker_status {
  text-align: center;
  margin-top: 180px;

  img {
    margin-bottom: 10px;
  }
  p {
    color: #25252599;
    font-size: 14px;

    &:first-of-type {
      font-family: GolosText-Bold, sans-serif;
    }
    &:nth-of-type(2) {
      font-family: GolosText-Medium, sans-serif;
    }
  }
}

.broker_status_start {
  text-align: center;
  margin-top: 180px;

  p {
    color: #000;
    font-family: GolosText-Medium, sans-serif;
    font-size: 18px;
  }
}