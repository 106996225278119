.ui-button {
  padding: 10px 30px;
  border-radius: 50px;

  background-color: var(--basic-dark);
  color: var(--basic-light);
  font-size: 15px;
  font-weight: 500;

  &-wrap {
    transform-origin: top;
    transition: 0.4s ease;

    display: flex;
    align-items: center;
    justify-content: center;

    column-gap: 4px;
  }

  &-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scaleY(0);
    overflow: hidden;

    transition: 0.4s ease;
    transform-origin: bottom;

    span {
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      position: relative;
      animation: rotate 0.6s linear infinite;
    }

    span::before,
    span::after {
      content: '';
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 2px solid var(--basic-light);
      animation: prixClipFix 2s linear infinite alternate-reverse;
    }
    span::after {
      transform: rotate3d(90, 90, 0, 180deg);
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes prixClipFix {
      0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
      }
      50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
      }
      75%,
      100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
      }
    }
  }

  &--text {
    width: fit-content !important;
    background-color: transparent;
    border-radius: 0;
    color: rgba(37, 37, 37, 0.6);
    font-size: 14px;
    padding: 3px;
    span {
      border-bottom: 1px dashed rgba(37, 37, 37, 0.6);
    }

    &:disabled {
      background-color: transparent !important;

      .ui-button-loading span {
        &::after,
        &::before {
          border-color: var(--basic-dark);
        }
      }
    }
  }

  &--primary {
    background-color: var(--basic-accent);
    color: var(--basic-light);
  }

  &--secondary {
    background-color: var(--basic-dark10);
    color: var(--basic-dark);

    font-family: 'GolosText-Medium';
  }

  &--outlined {
    background: none;
    border: 1px solid var(--basic-dark);
    color: var(--basic-dark);

    .main-icon path {
      fill: var(--basic-dark);
    }

    span::before,
    span::after {
      border-color: var(--basic-dark);
    }
  }

  &--clear {
    padding: 4px 8px 4px 8px;
    border-radius: 12px;
    background-color: transparent;
    color: var(--basic-dark40);

    .main-icon path {
      fill: var(--basic-dark40);
    }
  }

  &--size_sm {
    padding: 8px 40px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'GolosText-Medium';
  }

  &--loading {
    pointer-events: none;
    .ui-button-loading {
      transform: translate(-50%, -50%) scaleY(1);
    }
    .ui-button-wrap {
      transform: scaleY(0);
    }
  }
}
