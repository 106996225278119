@import '../../scss/variables.scss';
@import '../../scss/fonts';
@import '../../scss/mixins';

.favorit_wrp {
  position: relative;
  padding-bottom: 116px;
}
.blockTitle {
  color: #252525;
  margin-bottom: 10px;
}
.historySearchBlock {
  gap: 5px;
  display: flex;
  margin-bottom: 10px;
  .historySearchItem {
    background-color: #e3e6e8;
    color: #7c7c7c;
    border-radius: 20px;
    padding: 1px 8px;
    cursor: pointer;
  }
}

.all_but {
  cursor: pointer;
  color: $color_text_gr;
  font-size: 13px;
  font-family: 'GolosText-Medium';
  text-align: right;
  margin-top: 10px;
  text-decoration: underline;
}

.slider_bl {
  background: #ffffff;
  height: 203px;
  //padding: 16px;
  box-sizing: border-box;
  border-radius: 30px;
  border: 1px solid #eaeaea;
  width: 100%;
  position: relative;
  overflow: hidden;
  .graph {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;

    transform: scaleY(1);
    transform-origin: bottom;
    transition: 0.4s ease;

    svg {
      path {
        mix-blend-mode: normal !important;
      }
    }
    .grf_icon {
      cursor: pointer;
      position: absolute;
      bottom: 10px;
      right: 10px;
    }

    &Loading {
      transform: scaleY(0);
    }
  }
  > p {
    color: $color_text_gr2;
    text-align: center;
    font-size: 14px;
    font-family: 'GolosText-Medium';
    padding-top: 10px;
  }
  .flex_price {
    text-align: center;

    span {
      font-size: 16px;
      font-family: 'GolosText-Medium';
      img {
        display: inline-block;
        width: 16px;
      }
    }
  }

  .slider_mainprice {
    width: fit-content;
    margin: 0 auto;
    margin-top: 8px;
    line-height: 1.1;
    span {
      font-family: 'GolosText';
    }
  }

  .slider_mainpercent {
    margin: 0 auto;
  }
}

.slider_wrp {
  :global(.ant-carousel) {
    :global(.slick-dots) {
      align-items: center;
      z-index: 1 !important;
      position: relative !important;
      bottom: 0;
      margin-top: 8px;
      li {
        height: 6px;
        width: 6px;
        background: #e3e6e8;
        border-radius: 50%;
        button {
          font-size: 0;
          background: transparent;
        }
      }
      :global(.slick-active) {
        height: 10px;
        width: 10px;
        background: #3d3d3e;
      }
    }
    :global(.slick-slide) {
      > div {
        > a {
          height: 203px;
        }
      }
    }
  }
}

.popular_wrp {
  margin-bottom: 16px;
  width: 100%;
  &__scroll {
    display: flex;
    flex-wrap: nowrap;
    scrollbar-width: none;
    flex-direction: row;
    overflow-x: scroll;
    > div {
      margin-right: 10px;
    }
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      display: none;
      width: 0;
      height: 0;
    }
    *::-webkit-scrollbar-track {
      background: transparent;
    }
    *::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 5px;
      /*border: 3px solid orange;*/
    }
  }
}

.myCollections {
  animation: fadeOut 0.2s ease 1;
  @keyframes fadeOut {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  & > div {
    margin-bottom: 10px;

    &:last-of-type {
      margin: 0;
    }
  }
}

.geekoCollections {
  animation: fadeOut 0.2s ease 1;
  @keyframes fadeOut {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &Wrap {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;

    & > div {
      margin-right: 15px;

      &:last-of-type {
        margin: 0;
      }
    }
  }
}

.tabs_p {
  .css-1h5yfde {
    svg {
      fill: #939494;
      path {
        fill: #939494;
      }
    }
  }
}

.tab_scroll {
  position: relative;
  .TabsSymbol {
    > div {
      &:nth-child(2) {
        overflow-y: scroll;
        scrollbar-width: none;
        height: 100vh;
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          display: none;
          width: 0;
          height: 0;
        }
        *::-webkit-scrollbar-track {
          background: transparent;
        }
        *::-webkit-scrollbar-thumb {
          background-color: transparent;
          border-radius: 5px;
        }
      }
    }
  }
}
