.blocked_page {
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  >div {
    margin: 10px 0 56px;
    p {
      font-family: GolosText-Medium, sans-serif;
      color: #221F1C;
      font-size: 14px;
      line-height: 16.8px;
      white-space: nowrap;
      &:nth-of-type(2) {
        width: fit-content;
        border-bottom: 1px dashed #221F1C;
        margin: 4px auto 10px;
      }
      &:last-of-type {
        width: fit-content;
        border-bottom: 1px dashed #25252599;
        color: #25252599;
        margin: 0 auto;
      }
    }
  }

}