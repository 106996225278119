.header {
  position: fixed;
  top: 0;
  padding: 0 16px;
  left: 0;
  width: 100%;
  max-width: 576px;
  z-index: 6;
  right: 0;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #f5f5f5;
}
.buy_block_percent {
  text-align: center;
  font-size: 14px;
  color: var(--basic-dark40);
  button {
    margin: 0 4px;
    color: var(--basic-dark40);
  }
}
.backButton {
  color: #7c7c7c;
  height: 50px;

  img {
    vertical-align: middle;
  }
}

.buttonBlockFixed {
  position: fixed;
  bottom: 24px;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: 0px 16px;
  box-sizing: border-box;
  
  button {
    border-radius: 50px;
    width: 100%;
    font-size: 14px;
    max-width: 576px;
    height: auto;
    display: block;
    margin: 0 auto;
  }
}

.ticker {
  display: flex;
  align-items: center;
  justify-content: space-between;

  :global(.ticker-title) {
    flex: 1;
    margin-right: 15px;
  }

  &Types {
    margin-top: 20px;
    display: flex;

    &Item {
      position: relative;
      flex: 1;
      border-radius: 20px;
      padding: 10px 15px;
      font-weight: 500;
      background-color: white;

      svg {
        position: relative;
        z-index: 2;

        width: 40px;
        height: 40px;
      }
      path {
        fill: var(--basic-dark40);
        transition: 0.4s ease;
      }

      & > span {
        position: relative;
        z-index: 2;
        color: var(--basic-dark40);
        transition: 0.4s ease;
      }

      &::after {
        position: absolute;
        content: '';
        border-radius: inherit;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        transition: 0.6s ease;
      }

      &:nth-child(1) {
        margin-right: 20px;

        &::after {
          transform: translateX(101%);
          background-color: var(--misk-success);
        }
      }
      &:nth-child(2) {
        &::after {
          transform: translateX(-101%);
          background-color: var(--misk-error);
        }
      }

      &Active {
        span {
          color: white;
        }
        path {
          fill: white;
        }

        &::after {
          transform: none !important;
        }
      }
    }
  }
}

.freeFunds {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  >p {
    text-decoration: underline dashed;
    cursor: pointer;
  }
}

.orderType {
  margin-top: 20px;

  h3 {
    text-align: center;
    font-weight: 500;
    color: var(--basic-dark);
    margin-bottom: 10px;
  }

  &Wrap {
    height: 75px;
    padding: 0 30px;
    overflow: hidden;

    transition: 0.3s ease;

    max-height: 0;
    &Active {
      max-height: 75px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    label {
      display: block;
      width: 100%;
      text-align: center;
    }

    input {
      width: 100%;
      padding: 5px 0;
      text-align: center;
      background: transparent;
      font-family: inherit;
      appearance: textfield;
      border: none;
      font-size: 20px;

      //border-bottom: 1px solid var(--basic-dark);
    }
  }
}

.amount {
  margin-top: 20px;

  h2 {
    text-align: center;
    color: var(--basic-dark);
    font-weight: 500;
    font-size: 16px;
  }

  &Wrap {
    padding: 0 30px;
    display: flex;
    align-items: center;
    column-gap: 20px;
    :global(.ant-input-number) {
      width: 100% !important;
      border: 0 !important;
      background: transparent !important;
      border-radius: 0 !important;
      &:focus {
        box-shadow: none !important;
      }
    }
    :global(.ant-input-number-focused) {
      box-shadow: none !important;
    }
    :global(.ant-input-number-handler-wrap) {
      display: none !important;
    }
    :global(.ant-input-number-input-wrap) {
      width: 100% !important;
    }

    // input {
    //   width: 100% !important;
    //   flex: 1 !important;
    //   padding: 8px 0 !important;
    //   text-align: center !important;
    //   background: transparent !important;
    //   font-family: inherit !important;
    //   appearance: textfield !important;
    //   border-radius: 0 !important;
    //   border-top: 0 !important;
    //   border-left: 0 !important;
    //   border-right: 0 !important;
    //   font-size: 30px !important;
    //   border-bottom: 1px solid var(--basic-dark) !important;
    //   &:focus {
    //     box-shadow: none !important;
    //   }
    // }
  }

  &Btn {
    flex-shrink: 0;
    border: none;
    background: white;
    width: 50px;
    height: 50px;
  }

  &TextBtns {
    margin-top: 10px;
    display: flex;
    justify-content: center;

    button {
      padding-bottom: 2px;
      font-size: 15px;
      color: var(--basic-dark40);
      border-bottom: 1px solid var(--basic-dark40);
      cursor: pointer;

      &:active {
        opacity: 0.7;
      }
    }
  }
  .komiss_bl {
    font-size: 14px;
    text-align: center;
    padding-top: 8px;
    color: #d5b610;
  }
}

.block_choice {
  margin-top: 16px;
  text-align: center;
  margin-bottom: 16px;
  p {
    color: #221f1c;
    font-family: 'GolosText-Medium';
    font-size: 16px;
    text-align: center;
    margin-bottom: 16px;
  }
  label {
    span {
      font-size: 14px;
      color: rgba(37, 37, 37, 0.6);
      font-family: 'GolosText-Medium';
    }
  }
  :global(.ant-radio-wrapper .ant-radio-inner::after) {
    background-color: #000;
  }
  :global(.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after) {
    transform: scale(0.6);
  }
}

.dollarpref {
  :global(.ant-input-affix-wrapper) {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid var(--basic-dark) !important;
    border-radius: 0;
    input {
      text-align: center;
      font-size: 20px;
    }
  }
  :global(.ant-input-affix-wrapper-focused) {
    border-top: 0 !important;
    border-left: 0 !important;
    box-shadow: none;
    border-right: 0 !important;
    border-bottom: 1px solid var(--basic-dark) !important;
  }
  /* .prefix_bl {
    position: relative;
    &::before {
      content: "";
      background: url(../../assets/images/dollar.svg);
      position: absolute;
      width: 8px;
      z-index: 1;
    }
    input {
      width: auto;
    }
  }
  .prefix_bl {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }*/
}
