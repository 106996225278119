@import '../../scss/variables.scss';
@import '../../scss/mixins';

.header {
  position: sticky;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  padding: 10px 0;
  background-color: #f5f5f5;
  z-index: 1;
}
.title {
  color: #252525;
  font-size: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

.buttonOk {
  width: 100%;
  background-color: #252525;
  color: #ffffff;
  border-radius: 50px;
}
.tableRow {
  :global(.ant-table-cell-row-hover) {
    background: transparent !important;
  }
  :global(.ant-table-cell) {
    padding: 0 !important;
    border-bottom: 0 !important;
    :global(.anticon-menu) {
      padding-right: 10px;
    }
  }
}

.ticker_item {
  position: relative;
  margin: 5px 0;
  margin-right: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  // border-radius: 20px;
  // padding: 16px;
  // background: #ffffff;
  // border: 1px solid rgba(0, 0, 0, 0.1);

  .del {
    position: absolute;
    width: 24px;
    height: 24px;
    z-index: 3;
    top: -5px;
    right: -5px;
    // background: #252525;
    // border-radius: 50px;
    // cursor: pointer;

    // &:before {
    //   content: "";
    //   display: block;
    //   width: 10px;
    //   height: 2px;
    //   background: #ffffff;
    //   margin: 9px 5px;
    //   border-radius: 50px;
    // }
  }

  // &__text {
  //   margin-left: 10px;
  //   width: calc(100% - 100px);
  //   padding-right: 8px;
  //   p {
  //     font-size: 16px;
  //     font-family: "GolosText-Medium";
  //     color: $color_text_b;
  //   }
  //   span {
  //     font-size: 12px;
  //     color: $color_text_gr;
  //     font-family: "GolosText";
  //     display: block;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     text-transform: uppercase;
  //     white-space: nowrap;
  //     width: 100%;
  //   }
  // }
  // &__date {
  //   display: flex;
  //   overflow: hidden;
  //   width: calc(100% - 132px);
  // }
  // &__fl {
  //   display: flex;
  // }
  // &__price {
  //   p {
  //     font-size: 12px;
  //     text-align: right;
  //     color: $color_text_b;
  //     span {
  //       font-family: "GolosText";
  //     }
  //   }
  //   span {
  //     font-size: 13px;
  //     font-family: "GolosText-Medium";
  //   }
  //   margin-right: 8px;
  // }
}

.btnCancel {
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 50px;
  color: #7c7c7c;
}

.btnOk {
  background-color: #252525;
  border: 1px solid #252525;
  border-radius: 50px;
  color: #eaeaea;
}
.not_tickers {
  text-align: center;
  p {
    font-size: 14px;
    font-family: 'GolosText-Medium';
    color: #252525;
    margin-bottom: 4px;
  }
  span {
    color: #7c7c7c;
    display: block;
    margin-bottom: 16px;
    font-family: 'GolosText';
  }
  a {
    border-radius: 100px;
    background: #eaeaea;
    padding: 8px 40px;
    color: #252525;
    font-family: 'GolosText-Medium';
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    &:hover {
      text-decoration: none;
    }
  }
}

.copyCollection {
  :global(.main-icon path) {
    fill: var(--basic-dark60);
  }

  color: var(--basic-dark60);

  border: none;
  span {
    border-bottom: 1px dashed var(--basic-dark60);
  }
}

.editList {
  margin: 16px 0;
  max-height: 75svh;
  min-height: 40svh;
  overflow-y: auto;

  &Input {
    font-weight: inherit;
    font-size: inherit;

    width: 100%;
    padding: 5px 15px;

    font-size: 14px;
    color: var(--basic-dark40);

    font-size: 14px;
    border: 1px solid var(--basic-dark20);
    background: #fff;
    border-radius: 50px;
    appearance: none;
    box-shadow: 0 0 0 3px rgba($color: #000000, $alpha: 0);
    transition: 0.4s ease;

    &:hover,
    &:active,
    &:focus {
      border-color: var(--basic-dark);
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($color: #000000, $alpha: 0.1) !important;
    }
  }

  &Btns {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }

  &ConfirmBtn {
    flex: 1;
    padding: 0;
    width: 50%;
  }
}
