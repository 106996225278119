.search-input {
  position: relative;

  input {
    font-weight: inherit;
    font-size: inherit;

    width: 100%;
    padding: 5px 30px 5px 16px;

    font-size: 14px;
    color: var(--basic-dark40);

    font-size: 14px;
    border: 1px solid var(--basic-dark20);
    background: #fff;
    border-radius: 50px;
    appearance: none;
    box-shadow: 0 0 0 3px rgba($color: #000000, $alpha: 0);
    transition: 0.4s ease;

    &:hover,
    &:active,
    &:focus {
      border-color: var(--basic-dark);
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($color: #000000, $alpha: 0.1) !important;
    }
  }

  &-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    width: 16px;
    z-index: 1;
    pointer-events: none;

    transition: 0.2s ease;

    path {
      fill: var(--basic-dark40);
    }

    &.hide {
      opacity: 0;
      transform: translateY(-50%) scale(0);
    }
  }

  &--accent {
    input {
      border-color: var(--basic-accent);

      &:hover,
      &:active,
      &:focus {
        border-color: var(--basic-accent);
      }

      &:focus {
        box-shadow: 0 0 0 3px rgba($color: #7a24ea, $alpha: 0.1) !important;
      }
    }

    .main-icon path {
      fill: var(--basic-accent);
    }
  }
}
