@import '../../scss/variables.scss';
@import '../../scss/fonts';

.signDiv {
  background:
    repeating-linear-gradient(
                    transparent,transparent 20px, #ddd 22px
    ),
    repeating-linear-gradient(
                    90deg,
                    transparent,transparent 20px, #ddd 22px
    );
  background-color: white;
  min-height: 200px;
  min-width: 200px;
  margin-top: 15px;
  margin-bottom: 80px;
}

.podpis {
  //touch-action: pan-y!important;
}

.acc_closed_modal {
  text-align: center;
  p {
    font-family: 'GolosText-Medium', sans-serif;
    color: #221f1c;
    font-size: 20px;
    line-height: 24px;
    margin: 40px 0;
  }
  button {
    background-color: #7a24ea;
    border-radius: 100px;
    font-family: GolosText-Medium, sans-serif;
    color: #eaeaea;
    font-size: 16px;
    padding: 12px 64px;
  }
}

.acc_blocked_modal {
  text-align: center;
  p {
    font-family: GolosText-Medium, sans-serif;
    font-size: 14px;
    line-height: 16.8px;
    &:first-of-type {
      color: #000;
    }
    &:last-of-type {
      width: fit-content;
      border-bottom: 1px dashed #7a24ea;
      color: #7a24ea;
      margin: 4px auto 0;
    }
  }
}

.gr_btn_more {
  color: #eaeaea;
  border-radius: 100px;
  height: auto;
  box-sizing: border-box;
  padding: 12px 0px;
  font-size: 16px;
  background: #eaeaea !important;
  width: 100%;
  max-width: 544px;
  display: block;
  box-shadow: none !important;
  margin: 0 auto;
  background: #221f1c !important;
}
.gr_btn_update {
  text-align: center;
  background: none !important;
  padding: 0;
  margin: 16px auto;
  display: block;
  font-size: 14px;
  border: 0;
  span {
    color: #252525;
    border-bottom: 1px dashed;
    font-size: 14px;
    font-family: 'GolosText-Medium';
  }
}

.buttonBlockFixed {
  position: fixed;
  bottom: 24px;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
}
.brokerage_wrp {
  position: relative;

  :global(.react-code-input) {
    width: 100%;
  }
  :global(.container) {
    padding-bottom: 0 !important;
  }
  .buttonBlockFixed {
    position: fixed;
    bottom: 24px;
    left: 0;
    width: 100%;
    margin-bottom: 4px !important;
    padding: 0 16px;

    .gr_btn {
      border-radius: 100px;
      height: auto;
      box-sizing: border-box;
      padding: 12px 0px;
      font-size: 16px;
      background: #eaeaea !important;
      width: 100%;
      max-width: 544px;
      display: block;
      box-shadow: none !important;
      margin: 0 auto;
      span {
        color: rgba(0, 0, 0, 0.4);
      }
      &.activ {
        color: #eaeaea;
        background: #221f1c !important;
        span {
          color: #fff;
        }
      }
    }
  }
  .link_all {
    color: rgba(0, 0, 0, 0.4);
    display: inline-block;
    font-size: 14px;
    font-family: 'GolosText';
    border-bottom: 1px dashed;
    cursor: pointer;
  }
  .center {
    text-align: center;
  }
  .brokerage_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 16px;
    .back_bl {
      color: rgba(37, 37, 37, 0.6);
      font-size: 14px;
      display: flex;
      align-items: center;
      font-family: 'GolosText-Medium';
    }
    button {
      padding: 0;
      background: none;
      border: 0;
      height: 26px;
      box-shadow: none;
      margin: 0;
      svg {
        margin: 0;
        padding: 0;
      }
    }
  }
  :global(.ant-space-item) {
    span {
      color: #221f1c;
      font-size: 14px;
      font-family: 'GolosText';
    }
  }
  :global(.ant-radio-wrapper .ant-radio-inner::after) {
    background-color: #000 !important;
  }
  .flex_wrp {
    :global(.ant-checkbox-wrapper) {
      align-items: flex-start;
      display: flex !important;
      flex-direction: row;
    }
  }
  &.pages_bl {
    .group_link {
      a,
      span {
        &::before {
          content: none;
        }
      }
    }
  }
  .flex_number {
    :global(.PhoneInput) {
      input {
        border: 0;
        border-radius: 16px;
        padding: 8px 16px;
      }
    }
    img {
      height: 14px;
    }
  }
  :global(.react-code-input) {
    input {
      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
}

.text_bl {
  .black_text {
    color: #000;
    font-family: 'GolosText';
    font-size: 14px;
  }
  h4 {
    color: #221f1c;
    font-family: 'GolosText-Medium';
    font-weight: normal;
    margin-bottom: 16px;
    font-size: 20px;
    span {
      color: rgba(37, 37, 37, 0.6);
      display: block;
      margin-top: 4px;
      font-family: 'GolosText-Medium';
      font-weight: normal;
      font-size: 16px;
    }
  }
  h6 {
    color: #221f1c;
    font-family: 'GolosText-Medium';
    font-weight: normal;
    font-size: 16px;
  }
  .middl_text {
    font-size: 16px;
    font-family: 'GolosText-Medium';
    color: rgba(37, 37, 37, 0.6);
  }
  ul {
    margin: 0 0px 0 16px;
    padding: 0;
    li {
      font-family: 'GolosText';
      color: rgba(37, 37, 37, 0.6);
      font-size: 14px;
      margin-bottom: 8px;
    }
  }
  .simple_text {
    font-family: 'GolosText';
    color: rgba(37, 37, 37, 0.6);
    font-size: 14px;
    margin-bottom: 8px;
  }
  .lit_text {
    color: rgba(37, 37, 37, 0.6);
    font-size: 12px;
  }
  .lit_text1 {
    color: #221f1c;
    font-size: 12px;
    font-family: 'GolosText';
  }
}
.page_slider {
  position: relative;
  .hesd_slide {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 24px 0;
    a {
      cursor: pointer;
    }
  }
  :global(.ant-carousel .slick-dots li) {
    width: 33% !important;
  }
  :global(.ant-carousel .slick-dots) {
    position: fixed;
    max-width: 614px;
    padding: 0 16px;
    box-sizing: border-box;
    margin: 0 auto;
  }

  :global(.ant-carousel .slick-dots li button) {
    background: #c5c5c5 !important;
  }
}
.container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 576px;
  margin-right: auto;
  background-color: #1c2024;
  margin-left: auto;
  padding: 48px 0px 16px 0px;
  box-sizing: border-box;
}

.slider_b {
  .flex_bl {
    display: flex;
    flex-direction: column;
    height: calc(90vh - 200px);
    justify-content: space-between;
  }
  p {
    font-size: 22px;
    color: #fff;
    line-height: normal;
    font-family: 'GolosText-Medium';
    b {
      font-size: 24px;
      display: block;
      line-height: normal;
      font-family: 'GolosText-Bold';
    }
  }
  img {
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
}

.page_fl {
  display: flex;
  flex-direction: column;
  min-height: calc(100svh - 132px);
  justify-content: space-between;
  :global(.ant-carousel .slick-dots li button) {
    color: #c5c5c5 !important;
  }
}
.page_center {
  justify-content: center;
  align-items: center;
}
.start_but {
  font-size: 16px;
  color: #eaeaea;
  display: block;
  width: 100%;
  padding: 12px 24px;
  background: #221f1c;
  border-radius: 100px;
}
.start_but1 {
  font-size: 16px;
  color: #eaeaea;
  display: block;
  width: 100%;
  margin: 0 auto;
  max-width: 576px;
  padding: 12px 24px;
  background: #753bbd;
  border-radius: 100px;
}
.error_block {
  border-radius: 50px;
  display: block;
  background: #fffbe8;
  padding: 16px 16px 16px 80px;
  margin: 32px 0 24px 0;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    background: url(../../assets/images/erorimg.svg) 100% 50% no-repeat;
    width: 60px;
    z-index: 1;
    top: 0;
    left: 8px;
    height: 100%;
  }
  p {
    font-size: 12px;
    font-family: 'GolosText';
    color: rgba(0, 0, 0, 0.4);
    span {
      font-family: 'GolosText-Medium';
    }
  }
}

.bacc_block {
  button {
    font-size: 14px;
    padding: 8px 16px;
    font-family: 'GolosText';
    box-sizing: border-box;
    box-shadow: none !important;
    border-radius: 100px;
    height: auto;
    span {
      padding: 0;
      height: auto;
    }
    &:hover {
      border-color: #221f1c !important;
    }
  }
  .bacc_block_yes {
    border: 1px solid #221f1c;
    background-color: #221f1c !important;
    color: #fff !important;
    span {
      color: #fff !important;
    }
  }
  .bacc_block_no {
    color: #221f1c;
    background: #fff;
    border: 1px solid #221f1c;
    span {
      color: #221f1c;
    }
  }
}
.status_text {
  font-size: 12px;
  color: #221f1c;
  span {
    color: rgba(0, 0, 0, 0.4);
  }
}

.text_center {
  text-align: center;
  margin-top: 24px;
}

.group_link {
  a,
  span {
    cursor: pointer;
    display: block;
    border-radius: 16px;
    color: #221f1c;
    position: relative;
    padding: 8px 32px 8px 40px;
    background: #fff;
    font-size: 14px;
    margin-bottom: 16px;
    &::before {
      content: '';
      position: absolute;
      height: 14px;
      right: 16px;
      top: 30%;
      width: 14px;
      background: url(../../assets/images/arrowd.svg) no-repeat;
    }
    &::after {
      content: '';
      position: absolute;
      height: 14px;
      left: 16px;
      top: 30%;
      width: 14px;
      background: url(../../assets/images/img_bl.svg) no-repeat;
    }
    &.markCheck::before {
      background: url(../../assets/images/icon123.svg) no-repeat;
      border-radius: 0px;
      height: 12px;
      width: 12px;
      top: 36%;
      background-size: 100%;
      right: 18px;
    }
    b {
      height: 12px;
      width: 13px;
      display: inline-block;
      float: right;
      margin-top: 4px;
      margin-right: 10px;
      background: url(../../assets/images/icon123.svg) no-repeat;
    }
  }
  &.group_link1 {
    a,
    span {
      padding: 8px 32px 8px 16px;
      &::after {
        content: none;
      }
      &::before {
        content: '';
        position: absolute;
        height: 14px;
        right: 16px;
        top: 30%;
        width: 14px;
        background: url(../../assets/images/arrowd1.svg) no-repeat;
      }
      &.markCheck::before {
        background: #7a24ea;
        border-radius: 20px;
        height: 10px;
        width: 10px;
        top: 36%;
        right: 18px;
      }
    }
  }
}

.clear_ic {
  background: none !important;
  padding: 0;
  height: auto;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: 'GolosText';
  color: #7e7e7f;
  border: 0 !important;
  box-shadow: none !important;
  img {
    margin-right: 8px;
  }
}
.text_500 {
  font-family: 'GolosText-Medium';
  color: #7e7e7f;
  font-size: 14px;
  padding: 0;
}
.titile {
  font-size: 14px;
  font-family: 'GolosText-Medium';
  font-weight: 500;
  color: #221f1c;
  margin-top: 8px;
  margin-bottom: 16px;
}

.form {
  padding-bottom: 60px;
  fieldset {
    background: #fff;
    border-radius: 12px;
    margin-bottom: 16px;
    padding: 8px 16px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    > span {
      font-size: 12px;
      line-height: normal;
      display: block;
      margin-bottom: 16px;
      font-family: 'GolosText-Medium';
      color: rgba(0, 0, 0, 0.4);
    }
    :global(.ant-checkbox-wrapper) {
      width: 100%;
      span {
        font-size: 12px;
        font-family: 'GolosText-Medium';
        color: #221f1c;
      }
    }
    .input_text {
      width: 100%;
      span {
        display: block;
      }
      input {
        border-radius: 0;
        border-right: 0;
        border-left: 0;
        border-top: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      }
    }
    &.inputs_bl {
      :global(.ant-form-item) {
        label {
          font-size: 12px !important;
          color: rgba(0, 0, 0, 0.4) !important;
        }
      }
      :global(.ant-input) {
        font-size: 16px;
        border-top: 0;
        border-radius: 0;
        border-right: 0;
        padding: 0;
        border-left: 0;
        color: #221f1c;
        border-bottom: 1px solid #e3e6e8;
      }
    }
  }
  :global(.ant-form-item) {
    margin-bottom: 16px !important;
  }
  &.form2 {
    fieldset {
      padding: 0px 16px 4px 16px;
    }
    :global(.ant-form-item-label) {
      padding: 0 !important;
      margin: 0 !important;
    }
    :global(.ant-form-item) {
      padding: 0;
      margin-bottom: 0 !important;
      input {
        border: 0;
        font-size: 16px;
        font-family: 'GolosText-Medium';
        color: #221f1c;
        padding: 0;
      }
    }
    label {
      padding: 0;
      font-size: 12px;
      height: auto;
      font-family: 'GolosText-Medium';
      color: rgba(0, 0, 0, 0.4);
    }
    :global(.ant-select) {
      border: 0;
      font-size: 16px;
      font-family: 'GolosText-Medium';
      color: #221f1c;
      padding: 0;
    }
    :global(.ant-select-selector) {
      border: 0;
    }
    :global(.ant-picker) {
      width: 100%;
      border: 0 !important;
      padding: 0;
    }
  }
}

.brock_modal {
  padding: 8px 16px;
  p {
    padding: 4px 0;
    color: #000;
    font-family: 'GolosText';
    font-size: 12px;
  }
  h3 {
    font-family: 'GolosText-Medium';
    color: #000;
    font-size: 14px;
    margin-bottom: 16px;
    font-weight: normal;
  }
}

.brock_link {
  font-size: 12px;
  font-family: 'GolosText-Medium';
  border-bottom: 1px dashed;
  display: inline-block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  &:hover {
    color: rgba(0, 0, 0, 0.4);
  }
}
.fonn {
  background: #1c2024;
  top: 0;
  left: 0;
  height: 100%;
  right: 0;
  position: fixed;
}
