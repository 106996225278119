@import "../../scss/variables.scss";
.tagsTickers {
  margin: 0;
  border: 0;
  padding-top: 16px;
  background: transparent;
  line-height: inherit;
}
.tagsTickersPopup {
  margin: 50px;
}
.tagsTickerItem {
  line-height: 14px !important;
  height: 20px !important;
  text-align: center;
  margin-right: 4px;
  padding: 0 3px !important;
}
.tagsTickerItem:after {
  display: none;
}
.tagsTickerItemContent {
  color: $color_text_b;
  padding: 2px 0px;
  position: relative;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 1px dashed $color_text_gr;
  font-family: "GolosText-Medium";
  text-transform: uppercase;
  display: inline-block;
  width: 100%;

  span {
    font-size: 13px;
    margin-left: 8px;
  }
}

.plus {
  color: var(--misk-success);
}

.minus {
  color: var(--misk-error);
}
