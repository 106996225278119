.wrap {
  padding: 10px 10px 24px 10px;

  :global(.ui-button) {
    position: relative;
    z-index: 2;
    box-shadow: 0 -10px 15px rgba($color: #7a24ea, $alpha: 0.2);
  }
  input {
    font-size: 16px;
    font-family: 'GolosText';
  }
}

.choicedWrap {
  overflow-y: auto;
  height: 60svh;
}

.choicedEmpty {
  width: 100%;
  padding: 30px 0;

  text-align: center;
}

.SearchInput {
  width: 100%;

  padding: 6px 15px;
  font-family: 'GolosText-Medium';
  font-size: 16px;
  border-radius: 30px;

  box-shadow: 0 0 0 0px rgba($color: #000000, $alpha: 0) !important;
  border-color: var(--basic-dark20);
  color: var(--basic-dark);

  &:hover,
  &:active,
  &:focus {
    border-color: var(--basic-dark);
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba($color: #000000, $alpha: 0.1) !important;
  }
}

.label {
  font-weight: 500;
  margin-bottom: 5px;
}
