.geekoCollection {
  height: 125px;
  min-width: 146px;
  // border: 1px solid #252525;
  border-radius: 20px;
  font-family: GolosText-Medium, sans-serif;
  padding: 15px 0 15px 20px;

  > div:first-of-type {
    > div:first-of-type {
      display: none;
    }
    > div:last-of-type {
      > p {
        height: 33.6px;
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        line-height: 16.8px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 10px;
      }
      > div {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        div {
          margin-right: -4px;
        }
        div:last-of-type {
          height: 30px;
          background-color: #000;
          border-radius: 30px;
          padding: 8px;

          p {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
            line-height: 14.4px;
          }
        }
      }
    }
  }
  > div:last-of-type {
    display: flex;

    p {
      font-weight: 500;
      font-size: 13px;
      line-height: 15.6px;
      margin-right: 3px;
    }
  }
}

.myCollection {
  background-color: #fff;
  border-radius: 20px;
  font-family: GolosText-Medium, sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;

  p {
    color: #000;
    font-size: 16px;
    line-height: 19.2px;
  }
  > div:first-of-type {
    display: flex;
    align-items: center;

    > div:first-of-type {
      margin-right: 10px;
    }
    > div:last-of-type {
      width: 128px;

      > p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 4px;
      }
      > div {
        display: flex;
        align-items: center;

        div {
          margin-right: -4px;
        }
        div:last-of-type {
          height: 30px;
          background-color: #000;
          border-radius: 30px;
          padding: 8px;

          p {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
            line-height: 14.4px;
          }
        }
      }
    }
  }
  > div:last-of-type {
    display: flex;

    p {
      margin-right: 3px;
    }
  }
}
