@font-face {
  font-family: "GolosText";
  src: url("./fonts/GolosText/GolosText-Regular.eot");
  src: local("./fonts/GolosText/GolosText-Regular"), local("GolosText-Regular"),
    url("./fonts/GolosText/GolosText-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/GolosText/GolosText-Regular.woff") format("woff"),
    url("./fonts/GolosText/GolosText-Regular.ttf") format("truetype");
  src: local("GolosText"), url("./fonts/GolosText/GolosText-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "GolosText-Medium";
  src: url("./fonts/GolosText/GolosText-Medium.eot");
  src: local("./fonts/GolosText/GolosText-Medium"), local("GolosText-Medium"),
    url("./fonts/GolosText/GolosText-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/GolosText/GolosText-Medium.woff") format("woff"),
    url("./fonts/GolosText/GolosText-Medium.ttf") format("truetype");
  src: local("GolosText-Medium"), url("./fonts/GolosText/GolosText-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "GolosText-Semibold";
  src: url("./fonts/GolosText/GolosText-SemiBold.eot");
  src: local("./fonts/GolosText/GolosText-Semibold"),
    local("GolosText-Semibold"),
    url("./fonts/GolosText/GolosText-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/GolosText/GolosText-SemiBold.woff") format("woff"),
    url("./fonts/GolosText/GolosText-SemiBold.ttf") format("truetype");
  src: local("GolosText-Semibold"),
    url("./fonts/GolosText/GolosText-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "GolosText-Bold";
  src: url("./fonts/GolosText/GolosText-Bold.eot");
  src: local("./fonts/GolosText/GolosText-Bold"), local("GolosText-Bold"),
    url("./fonts/GolosText/GolosText-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/GolosText/GolosText-Bold.woff") format("woff"),
    url("./fonts/GolosText/GolosText-Bold.ttf") format("truetype");
  src: local("GolosText-Bold"), url("./fonts/GolosText/GolosText-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
