.collection-card {
  position: relative;

  .ui-clickable {
    border-radius: 16px;
    border: 1px solid var(--basic-dark10);
    background-color: var(--basic-light);
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-name {
    color: var(--basic-dark);
    font-family: 'GolosText-Medium';
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
  }

  &-symbols {
    display: flex;
    align-items: center;

    & > div {
      margin-right: -6px;

      &:first-child {
        transform: none;
      }
    }

    & > p {
      color: var(--basic-dark40);
    }

    &-more {
      min-width: 30px;
      padding: 0 10px;

      height: 30px;
      line-height: 30px;
      border-radius: 30px;

      color: var(--basic-light);
      background-color: var(--basic-dark);
    }
  }
}
