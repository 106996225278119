.price-changing {
  display: flex;
  height: 20px;

  &-pnl {
    margin-right: 10px;
    font-family: "GolosText-Medium";
    font-weight: 500;
    line-height: 0;
  }

  &-delimiter {
    margin-right: 6px;
    line-height: 0;
    transform: translateY(-2px);
    color: var(--basic-dark40);
  }

  &-percent {
    display: flex;
    align-items: center;
    
    &-value {
      margin-right: 3px;
      line-height: 0;
      font-family: "GolosText-Medium";
      font-weight: 500;
    }
  }

  &--md {
    .main-icon {
      width: 20px;
    }
  }

  &--lg {
    .price-changing-percent {
      font-size: 16px;
    }
  }

  &--neutral {
    color: var(--basic-dark40);
    path {
      fill: var(--basic-dark40);
    }
  }

  &--positive {
    color: var(--misk-success);
    path {
      fill: var(--misk-success);
    }
  }

  &--negative {
    color: var(--misk-error);
    path {
      fill: var(--misk-error);
    }
  }
}
