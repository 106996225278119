.borderblock {
  margin-top: 8px;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(44, 45, 52, 0) 0%,
    #0ff 51.83%,
    rgba(44, 45, 52, 0) 100%
  );
}
