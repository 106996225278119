.logo {
  line-height: 0;
  button {
    padding: 0;
    margin: 0;
    line-height: 0;
  }
  img {
    height: 23px;
    line-height: 0;
  }
}
