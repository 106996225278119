.image-preview {
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  border: 0;
  background-color: #f1f1f1;
  width: 96px;
  height: 96px;
  &::after {
    position: absolute;
    content: "";
    background: linear-gradient(
      152deg,
      rgba(217, 217, 217, 0.32) 14.34%,
      rgba(217, 217, 217, 0) 86.68%
    );
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
.image-preview img {
  display: block;
  height: 100%;
  margin: 0 auto;
  border-radius: 0;
}
