.geeko-chart {
  position: relative;
  width: calc(100% + 30px);
  transform: translateX(-15px);
  overflow: hidden;

  &-tools {
    padding: 0 10px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    border-top: 1px solid #ebedf3;
    border-bottom: 1px solid #ebedf3;

    & > div {
      display: flex;
      align-items: center;
    }

    font-size: 14px;

    .ui-clickable {
      padding: 5px 10px;
      width: auto;
      height: 100%;

      color: var(--basic-dark40);
      transition: 0.4s ease;

      .main-icon {
        width: 20px;
        height: 20px;

        path {
          fill: var(--basic-dark40);
        }
      }

      &.active {
        color: var(--basic-dark);

        path {
          fill: var(--basic-dark);
        }
      }
    }
  }

  &::after {
    position: absolute;
    content: '';

    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 220px;
    height: 100px;

    background-image: url(../../assets/images/logo_w.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    filter: grayscale(1);
    opacity: 0.06;
    pointer-events: none;
  }

  &-loader,
  &-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  &-error {
    font-size: 20px;
    font-weight: 500;
    white-space: nowrap;
  }

  tr:first-child {
    position: relative;

    td:nth-child(3) {
      position: relative;
      z-index: 3;
      transform: translateX(calc(0% + -5px));
    }
  }
}
