@import '../../scss/variables.scss';
@import '../../scss/fonts';

.personal_information_wrp {
  // min-height: calc(100vh - 44px);
  position: relative;
  :global(.header) {
    background: #0a0a0a !important;
  }
  .titile {
    font-size: 14px;
    font-family: 'GolosText-Medium';
    font-weight: 500;
    color: $color_text_b;
    margin-top: 8px;
    margin-bottom: 16px;
  }
  :global(.ant-space) {
    width: 100%;
  }
  .buttonBlockFixed {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 0 20px;
    :global(.ui-button) {
      border-radius: 50px;
      width: 100%;
      font-size: 14px;
      max-width: 576px;
      height: auto;
      display: block;
      margin: 0 auto;
    }
    &.margin_bottom24 {
      margin-bottom: 24px !important;
    }
  }
}
.personal_information.horizont_in {
  :global(.ant-col.ant-form-item-label) {
    max-width: 130px !important;
    text-align: left;
    label {
      font-size: 14px;
    }
  }
  :global(.ant-form-item-row) {
    display: flex !important;
    flex-direction: row !important;
    flex: none !important;
    :global(.ant-col) {
      display: flex !important;
      flex: auto !important;
      margin: 0;
      padding: 0;
      align-items: center;
    }
    :global(.ant-form-item-control) {
      width: auto !important;
      display: block !important;
      input {
        border-top: 0;
        font-size: 16px;
        border-radius: 0;
        background: transparent;
        padding: 8px 0;
        border-right: 0;
        border-left: 0;
      }
      p {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding: 8px 0;
        font-size: 16px;
      }
      :global(.ant-input-group-addon) {
        border-top: 0;
        border-left: 0;
        border-radius: 0;
        padding: 8px 8px 8px 0px;
      }
    }
  }
  .chance_pass {
    color: #00ffff;
    border: 0 !important;
    cursor: pointer;
  }
}

.img_block {
  :global(.ant-upload-wrapper) {
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  :global(.ant-upload-list-item-container) {
    margin: 0 auto !important;
    display: block !important;
  }
  :global(.ant-upload-list-item) {
    padding: 0 !important;
    border: 0 !important;
    border-radius: 50% !important;
    overflow: hidden;
    &::before {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
/*
:global(.ant-form-item-control) {
  :global(.ant-form-item-control-input) {
    :global(.ant-input-affix-wrapper) {
      font-size: 16px;
      border-top: 0;
      height: auto;
      border-left: 0;
      border-radius: 0;
      border-right: 0;
      &:focus,
      &:hover {
        box-shadow: none;
        border-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
  input {
    font-size: 16px;
    border-top: 0;
    border-left: 0;
    border-radius: 0;
    border-right: 0;
  }
}*/

:global(.ant-modal-content) {
  background-color: #1c1c1c;
  span,
  input,
  div {
    background: transparent;
  }
  :global(.ant-modal-header) {
    background: transparent;
  }
}

.security_wrp {
  display: block;
  img {
    display: block;
    margin: 20px auto;
  }
  span {
    font-size: 24px;
    text-align: center;
    display: block;
    margin-bottom: 18px;
  }
  p {
    font-size: 14px;
    text-align: left;
    font-family: 'GolosText';
    color: rgba(0, 0, 0, 0.4);
  }
}
.security_link {
  border-radius: 24px;

  .flex_tabl {
    flex-direction: row;
    img {
      width: 24px;
      margin-right: 16px;
    }
    padding: 16px 16px;
  }
  .flex_tabl1 {
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    > div {
      flex-direction: row;
      display: flex;
    }
    img {
      width: 24px;
      margin-right: 16px;
    }
    padding: 10px 16px;
  }

  &_item {
    width: 100%;
    box-sizing: border-box;
  }

  a,
  &_item {
    display: flex;
    align-items: center;
    padding: 8px 36px 8px 16px;
    position: relative;
    text-decoration: none;

    &:after {
      content: url(../../assets/images/arroww2.svg);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;

      right: 16px;
      font-size: 14px;
    }
    img {
      //height: 20px;\
      display: block;
      margin-right: 16px;
    }
    span {
      display: block;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.7);
    }
    p {
      display: block;
      font-size: 14px;
      color: $color_text_gr1;
      line-height: normal;
    }
  }
  ul {
    li {
      list-style-type: none;
      background: #fff;
      border-radius: 8px;
      margin-bottom: 8px;
    }
  }
  h3 {
    font-size: 20px;
    font-weight: normal;
    font-family: 'GolosText-Medium';
    margin-bottom: 16px;
  }
  .flex_tabl1 {
    &:last-child {
      border-bottom: 0 !important;
    }
  }
  &.bg_securt {
    background: #fff;
  }
  .one_account {
    border-bottom: 1px solid #e1e2e3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 4px;
    > div:first-of-type {
      div {
        width: fit-content;
        background-color: #7a24ea;
        border-radius: 5px;
        color: var(--basic-dark10);
        font-family: GolosText-Medium, sans-serif;
        font-size: 10px;
        text-transform: uppercase;
        padding: 2px;
        margin-bottom: 4px;
      }
      p {
        color: #959595;
        font-family: 'GolosText-Medium';
        font-size: 14px;
        &:last-of-type {
          color: #000;
        }
      }
    }
  }
}
.but_gr1 {
  width: calc(100% - 82px);
  p {
    white-space: nowrap;
    width: 99%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.but_gr {
  display: flex;
  flex-wrap: nowrap;
  width: 80px;
  button {
    &:first-child {
      margin-right: 8px;
    }
  }
}
.switch_bl {
  display: flex;
  padding: 16px 0;
  margin-bottom: 14px;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  justify-content: space-between;
  p {
    display: block;
    font-size: 16px;
    color: #ffffff;
    padding-bottom: 4px;
    line-height: normal;
  }
  span {
    display: block;
    line-height: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
  }
  :global(.ant-spin-nested-loading) {
    width: 100% !important;
    display: block;
  }
}
.switch_bl_link {
  padding-left: 0 !important;
  span {
    display: block;
    line-height: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6) !important;
  }
  &:after {
    content: url(../../assets/images/arrbl.svg) !important;
    position: absolute;
    top: 40% !important;
    width: 10px !important;
    bottom: 0;
    right: 0;
    font-size: 14px;
  }
}

:global(.ant-switch-handle) {
  &:before {
    background-color: #fff !important;
  }
}
:global(.ant-switch.ant-switch-checked .ant-switch-inner) {
  background: var(--basic-accent);
  border: 1px solid var(--basic-accent);
  &:hover {
    background: var(--basic-accent);
    border: 1px solid var(--basic-accent);
  }
}
:global(.ant-switch .ant-wave) {
  --wave-color: var(--basic-accent) !important;
  background: var(--basic-accent) !important;
}

:global(.ant-switch .ant-switch-inner) {
  background: #d3d3d3;
  border: 1px solid #d3d3d3;
}

.width_vv {
  max-width: 100%;
  margin: 0 auto;
}
.personal_pasnoborder {
  input {
    &:hover,
    &:focus {
      border: 0 !important;
    }
  }
}
.personal_pas {
  :global(.ant-input-affix-wrapper) {
    box-shadow: none !important;
  }
  input {
    background: #fff !important;
    font-size: 12px;
    color: $color_text_b;
    border: 1px solid #d3d3d3;
    border-radius: 24px;
    padding: 8px 16px;

    -webkit-box-shadow: 0 0 0px 1000px white inset;
    font-family: 'GolosText';
    &:hover,
    &:focus {
      border: 1px solid #d3d3d3;
    }
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
  :global(.ant-input-password) {
    background: #ffffff !important;
    padding: 8px 0;
  }
  :global(.ant-form-item) {
    margin-bottom: 16px !important;
  }
  :global(.ant-form-item-control .ant-form-item-control-input .ant-input-affix-wrapper) {
    border-radius: 100px;
    border: 1px solid #d3d3d3;
    padding: 8px 16px;
    input {
      &::placeholder {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
  .error_block {
    border-radius: 50px;
    display: block;
    background: #fffbe8;
    padding: 16px 16px 16px 80px;
    margin: 32px 0 24px 0;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      background: url(../../assets/images/erorimg.svg) 100% 50% no-repeat;
      width: 60px;
      z-index: 1;
      top: 0;
      left: 8px;
      height: 100%;
    }

    &_trusted {
      border-radius: 24px;
      padding-left: 16px;
      &::after {
        display: none;
      }
    }

    p {
      font-size: 12px;
      font-family: 'GolosText';
      color: rgba(0, 0, 0, 0.4);
      span {
        font-family: 'GolosText-Medium';
      }
    }
  }
}
.reports {
  margin-top: 16px;

  :global(.ant-radio-group) {
    width: 100%;
  }

  :global(.ant-space-item) {
    width: 100%;
    label {
      p {
        border: 0 !important;
        font-size: 16px;
        font-family: 'GolosText-Medium';
        color: $color_text_b;
        span {
          font-size: 12px;
          color: $color_text_gr;
          display: block;
        }
      }
    }
    :global(.ant-radio-wrapper) {
      width: 100%;
    }
  }
}
.but_report {
  position: fixed;
  bottom: 16px;
  z-index: 1;
}

.personal_changepass {
  p {
    color: rgba(255, 255, 255, 0.78);
    font-size: 14px;
    margin-bottom: 16px;
  }
  a {
    color: rgba(0, 255, 255, 1);
    font-size: 14px;
  }
  :global(.react-code-input) {
    input {
      border: 1px solid rgba(0, 255, 255, 0.1) !important;
      background: rgba(0, 255, 255, 0.02) !important;
      border-radius: 6px !important;
      color: #fff !important;
      width: 14.6% !important;
      margin: 1% !important;
      text-align: center;
      padding: 8px 0 !important;
      height: auto !important;
    }
  }
  .button_wrp {
    position: absolute;
    width: calc(100% - 32px);
    box-sizing: border-box;
    bottom: 50px;
  }
}
.button_save {
  display: block;
  width: 100%;
  border-radius: 24px;
  margin: 0 auto;
  border: 0;
  max-width: 544px;
  background: $color_text_b !important;
  color: #fff;
  padding: 6px 16px;
  height: auto;
  font-size: 16px;
  &:hover {
    color: #fff !important;
    background: $color_text_b !important;
  }
  &.active {
    color: #fff;
    background: $color_text_b !important;
    &:hover {
      color: #fff !important;
      background: $color_text_b !important;
    }
  }
}

.personal_language {
  :global(.ant-space-item) {
    width: 100%;
    background: #ffffff;
    border-radius: 20px;
    margin-bottom: 0px;
  }
  :global(.ant-radio-group) {
    width: 100%;
    label {
      font-size: 14px;
      padding: 8px 16px;
      width: 100%;
    }
  }
  :global(.ant-space-item):last-child label {
    border: 0;
  }
  :global(.ant-radio-wrapper .ant-radio-inner::after) {
    background-color: #000;
  }
  :global(.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after) {
    transform: scale(0.6);
  }
  &_btn {
    height: auto;
    margin-top: 24px;
    width: 100%;
    border-radius: 100px;
    font-family: 'GolosText-Medium';
    color: #fff;
    font-size: 16px;
    padding: 6px 0;
    background: $color_text_b;
    &:hover span {
      color: #fff;
    }
    span {
      color: #fff;
    }
  }
}
.dogovor_but {
  height: auto;
  margin-top: 24px;
  max-width: 400px;
  width: 100%;
  border-radius: 100px;
  font-family: 'GolosText-Medium';
  color: #fff;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  padding: 6px 0;
  position: absolute;
  bottom: 16px;
  background: $color_text_b;
}
.flex_tabl1 {
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center;
}
.flex_tabl,
.flex_tabl1 {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eaeaea;
  justify-content: flex-start;
  p {
    font-size: 11px;
    text-align: left;
    font-family: 'GolosText-Medium';
    color: $color_text_gr;
  }
  span {
    font-size: 14px;
    color: $color_text_b;
  }
}
.verify_id {
  font-size: 12px;
  text-align: center;
  color: rgba(255, 255, 255, 0.78);
  margin-bottom: 24px;
}

.photo_add {
  border-radius: 16px;
  background: linear-gradient(180deg, rgba(0, 255, 255, 0.1) 0%, rgba(0, 255, 255, 0) 100%);
  padding: 16px;
  margin-top: 24px;
  p {
    font-size: 14px;
    color: #ffffff;
    text-align: center;
  }
  :global(.ant-upload-wrapper) {
    width: 100%;
    margin-top: 24px;
  }
  :global(.ant-upload.ant-upload-select) {
    width: 100% !important;
    height: 150px !important;
  }
}

.switch_wrp {
  display: flex;
  align-items: center;
  padding: 9px 16px 9px 16px !important;
  &::after {
    content: none !important;
  }
  .switch_bl {
    width: 100%;
    border: 0;
    padding: 0;
    margin: 0;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.personal_language {
  :global(.ant-space-item) {
    width: 100%;
    label {
      p {
        border: 0 !important;
        font-size: 14px;
        font-family: 'GolosText-Medium';
        color: $color_text_b;
        text-transform: capitalize;
        span {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.4);
          font-family: 'GolosText';
          display: block;
        }
      }
    }
    :global(.ant-radio-wrapper) {
      width: 100%;
    }
  }
}

.reports_tab {
  :global(.ant-tabs-tab) {
    padding: 8px 0 !important;
    width: 50%;
    text-align: center;
    background-color: #e3e6e8;
    font-size: 14px;
    color: #000;
    border-radius: 12px;
    font-family: 'GolosText';
    &:hover,
    &:focus {
      div {
        color: #000;
      }
    }
    :global(.ant-tabs-tab-btn) {
      text-align: center;
      width: 100%;
      text-shadow: none !important;
      &:focus {
        color: #000 !important;
      }
    }
  }
  :global(.ant-tabs-tab-active) {
    background-color: #221f1c;
    :global(.ant-tabs-tab-btn) {
      color: #fff !important;
      font-family: 'GolosText';
      text-shadow: none;
      &:focus {
        color: #fff !important;
      }
    }
  }
  :global(.ant-tabs-ink-bar) {
    display: none !important;
  }
  :global(.ant-tabs-nav-list) {
    width: 100%;
  }
  :global(.ant-tabs .ant-tabs-tab + .ant-tabs-tab) {
    margin: 0 0 0 16px !important;
  }
}
.title_lit {
  font-family: 'GolosText' !important;
  font-size: 12px !important;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.4) !important;
}
.trusted_person {
  .link_block {
    p {
      font-size: 10px;
      color: #7a24ea;
      font-family: 'GolosText-Medium';
      display: block;
      margin-bottom: 8px;
      cursor: pointer;
    }
  }
}

.phone_input {
  width: 100%;

  :global(.react-international-phone-country-selector button) {
    border: none;

    padding-left: 8px;
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;

    transition: 0.3s ease;

    &:hover {
      background-color: var(--basic-dark20) !important;
    }
  }
  :global(.react-international-phone-input) {
    flex: 1;
    border: none !important;

    padding-left: 15px !important;
    border-bottom-right-radius: 16px !important;
    border-top-right-radius: 16px !important;

    font-family: inherit;
    font-size: 14px;
  }
}

.tech_p {
  p {
    font-size: 14px;
    color: rgba(37, 37, 37, 0.6);
    margin-bottom: 12px;
  }
}
.tech_p2 {
  margin-top: 24px;
  p {
    color: #221f1c;
    font-family: 'GolosText' !important;
  }
  b {
    font-weight: normal;
    font-family: 'GolosText-Medium';
  }
}
.titile_tech {
  font-family: 'GolosText-Medium';
  font-size: 16px;
  color: #221f1c;
  margin-bottom: 16px;
}
.text_reports {
  font-size: 14px;
  color: rgba(37, 37, 37, 0.8);
}

.change_btn {
  background: #eaeaea;
  border-radius: 16px;
  color: #7c7c7c;
  font-size: 10px;
  padding: 3px 6px;
}