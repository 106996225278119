@import "../../scss/variables.scss";

.modalOpen .ant-row:first-child {
  position: relative;
  z-index: 11;
}
.tabs_bl {
  font-family: GolosText;
  background: none;

  .ant-tabs-nav {
    padding: 0 40px;
  }
  .ant-tabs-tab {
    transition: none !important;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 !important;
  }
  .ant-tabs-nav-list {
    width: 100%;
    background-color: #fff;
    border-radius: 50px;
    padding: 5px 20px;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .ant-tabs-tab div {
    width: 100%;
    padding: 7px 10px;
    text-align: center;
    transition: none !important;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.4);

    &:focus {
      color: rgba(255, 255, 255, 0.4);
    }
  }
  .ant-tabs .ant-tabs-tab-btn {
    &:focus {
      color: rgba(255, 255, 255, 0.4);
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $white_text;
    background: $block_bg;
    border-bottom: 1px solid $yellow_color;
    text-shadow: none;
    transition: none !important;
    &:focus {
      color: rgba(255, 255, 255, 0.4);
    }
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
  }
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-nav-list {
    .ant-tabs-tab.ant-tabs-tab-active {
      &:first-child {
        .ant-tabs-tab-btn {
          border-radius: 0;
          background: transparent;
        }
      }
      &:nth-child(3) {
        .ant-tabs-tab-btn {
          border-radius: 0 16px 0 0;
        }
      }
    }
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    background: transparent;
  }
}
/*.modal_cont{
  .ant-modal{
    max-width: 544px;
    .ant-modal-content{
      background: none;
      box-shadow: none;
  
    }
    width: 100%;
    max-width: 544px;
    height: calc(90% - 60px);
    bottom: 0;
    top:60px; 
    left:0;
    right: 0px;
    margin: 0 auto;
    background: $background_block;
  }



  } 
  .ant-scrolling-effect{
    width: 100%;
  }*/

.filter_form {
  padding: 9px 13px;
  .ant-picker {
    width: 48%;
    color: #00000066;
    font-family: "GolosText-Medium", sans-serif;
    font-size: 12px;
    height: auto;
    padding: 0px 16px 0 0px;
    border-radius: 100px;
    border: 1px solid #00000066;
    input {
      padding: 11px 0 11px 14px;
      &:hover,
      &:focus {
        outline: none;
      }
    }
    &:hover {
      border-color: $border_color;
      outline: none;
    }
  }

  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-inner,
  .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: #252525;
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: #252525;
  }
  .ant-checkbox .ant-checkbox-inner::after {
    border-color: #252525;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #d3d3d3 !important;
    border-color: #252525;
  }
}
.add_balance {
  margin-top: 20px;
  input {
    font-size: 16px;
    border-top: 0;
    padding: 8px 0px;
    border-right: 0;
    border-left: 0;
    border-color: $border_color;
    &:focus,
    &:hover {
      box-shadow: none;
    }
  }
  .ant-input-group-addon {
    border-right: 0;
    border-left: 0;
    border-top: 0;
    border-color: $border_color;
    background-color: transparent;
  }
}
.title_instr {
  font-size: 18px;
  font-family: "GolosText-Medium";
  padding: 8px 0;
}
.pdf_file {
  background: #fff;
  margin-top: 48px;
  padding: 16px 16px 72px 16px;
  table {
    color: #000;
    width: 100%;
    img {
      width: 150px;
    }
    span {
      display: block;
      font-size: 12px;
    }
    .title_pdf {
      font-size: 18px;
      font-family: "GolosText-Medium";
      padding-bottom: 24px;
    }
  }
  b.text_pdf {
    font-family: "GolosText-Medium";
    font-weight: normal;
  }
}
.btn_pdf {
  margin: 24px auto;
  display: block;
  padding: 6px 24px;
  height: auto;
  width: auto;
  border: 0;
  background: $border_color !important;
  border-radius: 16px;
}
@media screen and (max-width: 400px) {
  .tabs_bl .ant-tabs-nav {
    padding: 0 35px;
  }
}
@media screen and (max-width: 360px) {
  .tabs_bl .ant-tabs-nav {
    padding: 0 35px;
  }
}
