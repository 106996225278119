@import '../../scss/variables.scss';
@import '../../scss/fonts';

.text_bold {
  font-family: 'GolosText-Medium';
}

.notificc {
  :global(.ant-tabs-nav) {
    background: #fff;
    border-radius: 50px;
    padding: 8px 16px;
    width: 326px;
    margin: 0 auto 16px;
    max-width: 100%;
  }
  :global(.fixed2) {
    :global(.ant-tabs-nav) {
      top: 65px;
      background: transparent;
    }
    :global(.ant-tabs-content-holder) {
      padding-top: 0;
    }
  }
  .switch_bl {
    display: flex;
    padding: 8px 16px;
    margin-bottom: 14px;
    border-radius: 8px;
    align-items: center;
    background-color: #ffffff;
    justify-content: space-between;
    p {
      display: block;
      font-size: 14px;
      color: $color_text_b;
      line-height: normal;
    }
    span {
      display: block;
      line-height: normal;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
    }
    :global(.ant-spin-nested-loading) {
      width: 100% !important;
      display: block;
    }
  }

  .tabs_block {
    :global(.ant-tabs-tab) {
      margin: 0 4px !important;
      font-family: 'GolosText' !important;
      border-radius: 24px !important;
      padding: 8px 16px !important;
      box-sizing: border-box;
      div {
        text-align: center;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: 10px !important;
        color: #252525 !important;
        font-family: 'GolosText-Medium';
        margin: 0 auto;
      }

      @media screen and (max-width: 440px) {
        width: auto !important;
        min-width: auto;
        padding: 8px 16px !important;
      }
      p {
        width: 18px;
        height: 18px;
        display: block;
        text-align: center;
        color: #fff;
        margin: 0;
        padding-top: 0px;
        font-size: 10px;
        margin-left: 4px;
        border-radius: 50%;
        background: #ff451d;
      }
    }
    :global(.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
      text-shadow: none !important;
      color: #fff !important;
      font-family: 'GolosText-Semibold';
      font-size: 12px;
    }
    :global(.ant-tabs .ant-tabs-tab.ant-tabs-tab-active) {
      background: #252525;
      border-radius: 8px;
      position: relative;
    }

    :global(.ant-tabs .ant-tabs-ink-bar) {
      background: transparent;
    }
  }

  .no_notific {
    display: block;
    text-align: center;
    height: 100%;
    padding-top: 24px;
    p {
      margin-top: 16px;
      text-align: center;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .height_notific {
    overflow-y: scroll;
    box-sizing: border-box;
    height: calc(100vh - 124px);
    padding-bottom: 50px;
  }

  .notification_item {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    background: #ffffff;
    align-items: center;
    margin-bottom: 10px;
    padding: 6px 16px;
    border-radius: 8px;
    &__bg {
      height: 32px;
      display: block;
      width: 32px;
      margin-right: 16px;
      border-radius: 50%;
    }
    h3 {
      font-size: 16px;
      font-family: 'GolosText-Medium';
      font-weight: normal;
      color: rgba(255, 255, 255, 0.8);
      margin: 0;
    }
    p {
      font-size: 14px;
      line-height: normal;
      margin-top: 4px;
      color: $color_text_b;
    }
    span {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.4);
      white-space: nowrap;
    }
    &__sc {
      width: calc(100% - 48px);
      margin-left: 4px;
    }
  }
}

.notification_new {
  &::after {
    background: #753bbd;
    border-radius: 14px;
    content: '';
    height: 8px;
    right: 16px;
    position: absolute;
    top: 45%;
    width: 8px;
  }
}

.add_notific {
  font-size: 16px;
  color: #ffffff;
  border-radius: 24px;
  padding: 12px 24px;
  margin: 30px auto;
  display: block;
  width: 280px;
  bottom: 0px;
  box-sizing: border-box;
  left: 50%;
  background: #169d47;
}
.border_panel {
  margin-top: 20px;
  height: 1px;
  background: linear-gradient(90deg, rgba(44, 45, 52, 0) 0%, #0ff 51.83%, rgba(44, 45, 52, 0) 100%);
}

.flex_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    p {
      background: rgba(255, 255, 255, 0.1);
      padding: 2px 16px;
      border-radius: 12px;
      margin-right: 10px;
      svg {
        width: 14px;
      }
    }
  }
}
.top_panel {
  padding: 8px 0;
}
.favorit_bl {
  padding-top: 16px;
}
.form_signal {
  :global(.ant-input-number-affix-wrapper) {
    width: 100%;
    text-align: right;
    padding: 4px 16px;
    height: auto;
    border: 0;
    font-size: 24px;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: 0px;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 255, 255, 0.1);
    margin-bottom: 8px;
    &:hover,
    &:focus {
      border-bottom: 1px solid rgba(0, 255, 255, 0.1);
      box-shadow: none;
    }
  }
  :global(.ant-input-number-input) {
    font-size: 24px;
    text-align: right;
    padding: 4px 0;
  }
  span {
    color: rgba(132, 132, 132, 1);
    font-size: 14px;
  }
}
.add_signals {
  b {
    text-align: center;
    display: block;
    margin: 0 auto 16px;
    font-weight: normal;
    font-size: 16px;
    color: rgba(132, 132, 132, 1);
    padding: 8px 0;
  }
}

.page_sign {
  div {
    button {
      &:nth-child(2) {
        display: none;
      }
    }
  }
}

.norific_header {
  text-align: left;
  h3 {
    font-size: 20px;
    font-family: 'GolosText-Medium';
    color: #ffffff;
    font-weight: normal;
    padding: 0;
  }
  span {
    padding: 0;
    margin-top: 8px;
    display: block;

    font-size: 10px;
  }
}
.text_notific {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
}

.all_panel {
  display: flex;
  padding: 8px 0;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 12px;

    color: rgba(255, 255, 255, 0.39);
  }
  span {
    color: #bca6ff;
    font-size: 12px;
    cursor: pointer;
  }
}

.flex_b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal_date {
  font-size: 12px;
  color: #a8a8a8;
  display: block;
  text-align: right;
}

.read_all {
  text-align: right;
  display: block;
  margin-bottom: 8px;
  font-family: 'GolosText';
  cursor: pointer;
  color: $color_text_gr;
  font-size: 12px;
}

.notification_page {
  padding-top: 14px;

  > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    p:first-of-type {
      color: $color_text_b;
      font-size: 16px;
    }
    p:last-of-type {
      font-size: 12px;
    }
  }
  > p:first-of-type {
    color: $color_text_b;
    margin-bottom: 14px;
  }
  > p:nth-of-type(2) {
    font-size: 13px;
    margin-bottom: 18px;
  }
  a {
    text-decoration: underline;
    color: rgba(37, 37, 37, 0.6);
  }
  p {
    color: rgba(37, 37, 37, 0.6);
    font-size: 14px;
  }
  .text_date {
    color: #959595;
  }
}
