@import '../../scss/variables.scss';
@import '../../scss/fonts';
@import '../../scss/mixins';

.wallet_wrp {
  height: auto;
  //height: calc(100vh - 123px);

  &_history {
    height: calc(100svh - 44px);
    overflow: hidden;

    display: flex;
    flex-direction: column;

    &_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
    }

    :global(.ticker-order-card) {
      animation: fadeOut 0.3s ease 1;
      @keyframes fadeOut {
        from {
          opacity: 0;
          transform: scale(0.98);
        }
      }
    }

    &_title {
      font-size: 18px;
      font-family: 'GolosText-Medium';
      font-weight: normal;
      color: #221f1c;
    }

    :global(.filter-chip-wrap) {
      position: relative;
      width: 100%;
      top: auto;
    }

    :global(.orders-list) {
      height: auto !important;
      flex: 1 1 auto;
    }
  }
}

//:global(.ant-picker) {
//  border-radius: 8px !important;
//  border: 1px solid rgba(44, 45, 52, 0.35);
//  background: linear-gradient(180deg, #181c20 0%, rgba(9, 12, 15, 0) 100%);
//  box-shadow: 0px 4px 4px 0px rgba(0, 255, 255, 0.04);
//}

.filtr_backgr {
  background-color: #fff;
}
.new_pric {
  font-size: 16px;
  color: $white_text;
  font-family: 'GolosText-Medium';
}
.price_free {
  font-family: 'GolosText';

  p:first-of-type {
    color: #a8a8a8;
    font-family: 'GolosText-Medium';
    font-size: 16px;
    line-height: 19.2px;
    text-decoration: underline dashed;
    margin-bottom: 6px;
    cursor: pointer;
  }
  p:last-of-type {
    color: #252525;
    font-size: 22px;
  }
}
.money_bl {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  &__one {
    p {
      font-size: 16px !important;
      color: $white_text !important;
      font-family: 'GolosText-Medium';
    }
    span {
      text-transform: uppercase;
      font-size: 18px;
      font-family: 'GolosText-Medium';
    }
  }
  &__fl {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.4);
    p {
      font-size: 13.5px;
      font-family: 'Roboto';
      margin: 0 0 0 5px;
      color: rgba(255, 255, 255, 0.3);
    }
    span {
      font-size: 14px;
      font-family: 'Roboto';
      font-size: 12px;
    }
  }
}
.verif_bl_gray {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .verif_bl {
    width: 100%;
    p {
      position: relative;
      text-align: center;
      top: auto;
      color: rgba(37, 37, 37, 0.6);
      font-size: 14px;
      right: auto;
      font-family: 'GolosText';
      left: auto;
      white-space: wrap;
      transform: translate(0%, 0%);
      b {
        font-weight: normal;
        display: block;
        font-family: 'GolosText-Semibold';
      }
    }
    a {
      position: relative;
      margin: 32px auto;
      background: #221f1c;
      display: block;
      right: auto;
      max-width: 230px;
      left: auto;
      text-align: center;
      transform: translate(0%, 0%);
    }
    img {
      margin: 0 auto 32px;
      display: block;
    }
  }
}
.block_one {
  cursor: pointer;
  padding: 2px;
  border-radius: 16px;
  width: 100%;
  display: block;
  background: linear-gradient(109.8deg, #bca6ff 29.49%, rgba(15, 235, 239, 0.42) 79.1%);
  .block_one_wrp {
    background: #1e1f23;
    padding: 35px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
    p {
      font-size: 16px;
      color: #fff;
    }
    img {
      transform: rotate(180deg);
    }
  }
  .img_fff {
    border-radius: 50%;
    width: 30px;
    transform: rotate(0deg) !important;
    margin-right: 16px;
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}
.tooltip_text {
  color: $gray_text;
  text-align: right;
  font-size: 11px;
  display: block;
}
.graph {
  //background: chocolate;
  min-height: 150px;
  margin-top: 16px;
}
.free_bl {
  border: 1px solid rgba(188, 166, 255, 0.1);
  background: linear-gradient(180deg, rgba(188, 166, 255, 0.06) 0%, rgba(20, 21, 25, 0) 100%);
  padding: 16px;
  @include border-radius(8px);
  margin-bottom: 36px;
  span {
    font-size: 14px;
    color: $gray_text;
  }
  &__one {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    p {
      font-size: 24px;
      color: $white_text;
      font-weight: 700;
      margin-bottom: 8px;
    }
    span {
      font-size: 24px;
      font-family: 'Roboto-bold';
      color: $white_text;
    }
    .wallet_texx {
      font-size: 12px;
      font-family: 'Roboto';
      color: rgba(255, 255, 255, 0.3);
      margin-bottom: 16px;
    }
    button {
      color: $color_new;
      padding: 8px;
      font-size: 12px;
      svg {
        height: 12px;
        width: 12px;
        float: left;
        margin: 0px 8px 0 0;
        .fill_color {
          fill: $color_new;
        }
        .fill_color1 {
          stroke: $color_new;
        }
      }
    }
  }
  &__buttons {
    button {
      &:first-child {
        margin-right: 16px;
      }
    }
  }
}
.block_not {
  text-align: center;
  padding: 0 24px;
  margin-top: 24px !important;
  p {
    color: #00000066;
    font-size: 13px;
    line-height: 15.6px;
    margin-bottom: 32px;
  }
  button {
    width: 100%;
    background-color: #252525;
    border-radius: 100px;
    color: #fff;
    font-family: GolosText-Medium, sans-serif;
    font-size: 14px;
    line-height: 16.8px;
    text-align: center;
    padding: 10px 0;
  }
}
.tabs_bl {
  margin-top: 24px;
  background: $background_block;
  @include border-radius(16px);
}
.tabs_block {
  // position: sticky;
  // top: 48px;
  background-color: var(--secondary-light);
  z-index: 4;

  width: calc(100% + 30px);
  margin-left: -15px;

  padding: 16px 50px;

  :global(.ui-tabs-wrap) {
    padding: 0 15px;
    justify-content: space-between;
  }
  :global(.ui-tabs .ui-clickable) {
    padding: 10px 10px;
    font-size: 10px;
    font-family: 'GolosText-Medium';
    text-transform: uppercase;
  }

  /* INACTIVE */
  :global(.ant-tabs-tab div) {
    color: #000 !important;
    font-family: 'GolosText-Medium';
    font-size: 10px !important;
    text-transform: uppercase;
  }
  :global(.ant-tabs-tab-active) {
    color: rgba(255, 255, 255, 0.88) !important;
    div {
      color: #fff !important;
      font-family: 'GolosText-Semibold';
      font-size: 10px !important;
      text-transform: uppercase;
    }
  }
  :global(.ant-tabs) {
    font-family: 'GolosText';
    border-radius: 24px;
    transition: none !important;
  }
  :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
    border-radius: 24px !important;
    background: #000 !important;
    border: 0 !important;
    transition: none !important;
    font-size: 14px;
  }
  :global(.ant-tabs-tab.ant-tabs-tab-active:nth-child(2) .ant-tabs-tab-btn) {
    border-radius: 24px !important;
  }
  :global(.ant-tabs-tab.ant-tabs-tab-active:nth-child(3) .ant-tabs-tab-btn) {
    border-radius: 24px !important;
  }
}
.tabs_info {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 13px;
  // border-color: var(--basic-dark40);
  // color: var(--basic-dark40);

  background-color: var(--basic-light);
  border-color: var(--basic-dark20);
  color: var(--basic-dark40);
}
.filter_bl {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  p {
    color: $gray_text;
  }
  button {
    color: $yellow_color;
    padding-right: 0;
    font-size: 12px;
  }
  h4 {
    margin: 0;
  }
}
.navigate_button {
  position: absolute;
  z-index: 1;
  display: none;
  width: 100%;
  background: #1a1922;
  padding: 4px 0;
}
.top_button {
  bottom: 0;
  img {
    transform: rotate(180deg);
  }
}
.bottom_button {
  top: 38px;
}

.filter_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding: 0 40px;
  margin: -9px 0 18px;
  svg {
    margin: 0 auto;
    display: block;
  }
  .filter_close {
    stroke: #7c7c7c;
    stroke-width: 1;
    stroke-linecap: butt;
    stroke-dasharray: 0;
  }
  .filter_close1 {
    stroke: #7c7c7c;
    fill: #fff;
  }
  p {
    font-family: 'GolosText-Medium', sans-serif;
    font-size: 10px;
    color: #7c7c7c;
    background: #e3e6e8;
    text-align: center;
    cursor: pointer;
    padding: 6px 9px 6px 5px;
    @include border-radius(24px);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: inline-block;
      svg {
        height: 16px;
        margin-left: 4px;
        width: 16px;
      }
    }
  }
}

.filter_block_no {
  background: transparent;
  padding: 16px 0;
}
.fl_bl {
  h4 {
    font-size: 14px;
    margin: 0;
  }
  padding: 24px 0 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  button {
    color: $color_new;
    font-size: 12px;
    svg {
      height: 16px;
      width: 16px;
      float: left;
      margin: 0px 8px 0 0;
      .fill_color {
        fill: $color_new;
      }
      .fill_color1 {
        stroke: $color_new;
      }
    }
  }
}
.order_bl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid $border_color;
  &:last-child {
    border-bottom: 0;
  }
  &__date {
    p {
      color: $white_text;
      font-size: 14px;
    }
    span {
      font-size: 12px;
      color: $white_text;
      display: block;
      &.date_gray {
        color: $gray_text;
      }
    }
  }
  &__img {
    display: flex;
    align-items: center;
  }
  &__text {
    margin-left: 16px;
    p {
      color: $white_text;
      font-size: 16px;
      font-weight: 500;
    }
    .cat {
      font-size: 14px;
      color: $white_text;
      padding: 0;
      margin: 0;
    }
    .date {
      color: $gray_text;
      font-size: 12px;
      display: block;
    }
  }
}
.label_form {
  font-size: 14px;
  color: #000;
  font-family: 'GolosText-Medium', sans-serif;
}
.flex_bl {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .filter_block {
    background: transparent;
    padding: 0;
  }
  .but_add {
    svg {
      height: 16px;
      width: 16px;
      .color {
        fill: $yellow_color;
      }
    }
  }
  input {
    width: 48%;
    font-family: 'GolosText-Medium', sans-serif;
    font-size: 16px;
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid $border_color;
  }
  .check_group {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
}

.clear {
  text-transform: capitalize;
  font-family: 'GolosText-Medium', sans-serif;
  font-size: 10px;
  color: #00000066;
  text-decoration: underline dotted;
  cursor: pointer;
}
.flex_item {
  padding-bottom: 23px;
  margin: 0;
  &:last-child {
    border-bottom: 0;
  }
  input {
    border-radius: 100px;
    border: 1px solid #00000066;
    font-family: 'GolosText-Medium', sans-serif;
    font-weight: 500;
    font-size: 12px;
    padding: 11px 14px 11px;
    &:focus,
    &:hover {
      //border: 1px solid $border_color;
      border-color: $border_color !important;
    }
  }
}
.but_more {
  font-size: 14px;
  border: 1px solid $border_color;
  border-radius: 16px;
  text-align: center;
  display: block;
  padding: 4px 0;
  color: $gray_text;
  margin: 24px auto;
  cursor: pointer;
  width: 80%;
}
.datapick_input {
  font-size: 16px;
}
.search_akc {
  width: 100% !important;
  font-size: 16px;
  margin-top: 4px;
  text-transform: uppercase;
}
.cancel_but {
  color: $red;
  text-decoration: underline;
  font-size: 12px;
  text-align: right;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.text_b {
  background: rgba(255, 255, 255, 0.07);
  border-radius: 16px;
  padding: 16px;
  p {
    font-size: 12px;
    color: #fff;
    margin-bottom: 16px;
  }
}

.text_red {
  background: rgba(210, 36, 36, 0.1);
  border-radius: 16px;
  padding: 16px;
  p {
    color: #bd2f2f;
    font-size: 12px;
  }
}
.block_valute {
  margin-top: 24px;
  > p {
    color: #fff;
    font-size: 16px !important;
    font-weight: 600;
    margin-bottom: 16px;
  }
  button {
    height: auto;
    font-size: 16px;
    background: linear-gradient(180deg, #0febef 11.46%, #76c2f9 72.4%, #bca6ff 100%);
    color: #000000;
    padding: 16px 0;
    font-family: 'Roboto-Medium';
    border-radius: 8px;
    width: 100%;
  }
  form {
    :global(.ant-input-number) {
      width: 100%;
      text-align: center;
      font-size: 24px;
      background-color: transparent;
      border: 0;
      font-family: 'Roboto';
      input {
        text-align: center;
        background-color: transparent;
        border: 0;
      }
    }
    :global(.ant-input-number-handler-wrap) {
      display: none;
    }
    label {
      font-family: 'Roboto-Medium';
      font-size: 16px !important;
      color: #fff !important;
    }
  }
}
.summ_block {
  border-radius: 16px;
  padding: 16px;
  margin-top: 24px;
  background: linear-gradient(180deg, rgba(0, 255, 255, 0.07) 0%, rgba(0, 255, 255, 0.01) 100%);
  .block_valute {
    margin-top: 0;
  }
}
.butt_b {
  font-size: 16px;
  background: linear-gradient(180deg, #0febef 11.46%, #76c2f9 72.4%, #bca6ff 100%);
  color: #000000;
  padding: 16px 0;
  font-family: 'Roboto-Medium';
  border-radius: 8px;
  width: 100%;
}
.line_one {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  display: flex;
  padding: 8px 0;
  flex-direction: row;
  span {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    display: block;
    width: 100px;
    margin-right: 16px;
  }
  p {
    font-size: 14px;
    color: #fff;
    width: calc(100% - 116px);
  }
  .line_one_widt {
    width: 100%;
  }
}
.add_balance_title {
  font-size: 20px;
  padding-bottom: 0px;
  color: #ffffff;
  //border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.table_block {
  background: #0a0a0a;
  padding: 4px 0;
  display: block;
  margin-bottom: 16px;

  tr {
    width: 100%;
    padding: 2px 8px;
    display: block;
  }
  td {
    width: 50%;
    box-sizing: border-box;
    display: inline-block;
    &:last-child {
      text-align: right;
    }
  }
}
.table_title {
  font-size: 16px !important;
  font-family: 'Roboto-Medium';
  color: #fff;
}
.table_title_gr {
  font-size: 16px !important;
  font-family: 'Roboto-Medium';
  color: #a8a9ad !important;
  padding-bottom: 16px;
}
.table_bord {
  border-bottom: 1px solid #212121;
  margin-bottom: 8px;
  p {
    font-family: 'Roboto-Medium';
    color: #fff;
    font-size: 14px;
  }
  input {
    padding: 8px 0;
    font-size: 16px;
    background: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    &:focus {
      border: 0;
      box-shadow: none;
    }
  }
  span {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.4);
    line-height: normal;
    display: block;
  }
}
/*:global(.ant-form-item-label label) {
  font-size: 12px !important;
  color: rgba(255, 255, 255, 0.4) !important;
  line-height: normal !important;
}
:global(.ant-form-item) {
  margin-bottom: 4px !important;
}*/
.table_titl {
  color: #fff;
  font-size: 18px !important;
  font-family: 'Roboto-Medium';
  margin-bottom: 0px !important;
  margin-top: 16px;
}
.color_d {
  color: #00ffff !important;
}

.old_transfer {
  margin-top: 24px;
  .old_transfer_table {
    margin-top: 16px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    .table_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      padding: 8px 16px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      &:last-child {
        border-bottom: 0px;
      }
      p {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}
.block_a {
  position: relative;
  a {
    &:first-child {
      border-top: 0;
    }
  }
}

.investments {
  width: 100%;
  font-family: 'GolosText';

  > p:first-of-type {
    color: #a8a8a8;
    font-family: 'GolosText';
    font-size: 16px;
    line-height: 19.2px;
    margin-bottom: 9px;
    cursor: pointer;
  }
  > p:nth-of-type(2) {
    color: #252525;
    font-size: 22px;
    margin-bottom: 6px;
  }
  > div {
    font-family: 'GolosText-Medium';
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 19.2px;
      cursor: pointer;

      &:nth-of-type(2) {
        color: #d3d3d3;
        margin: 0 6px;
      }
    }
    img {
      margin-left: 3px;
    }
  }
}

.tab_content {
  > div,
  > a {
    margin-bottom: 8px;

    &:last-of-type {
      margin: 0;
    }
  }
}

.info {
  padding: 9px 16px;

  p:first-of-type {
    color: #252525;
    font-family: 'GolosText-Medium';
    font-size: 16px;
    margin-bottom: 16px;
  }
  p:last-of-type {
    color: #7c7c7c;
    font-size: 14px;
  }
}

.empty_block {
  padding: 20px;

  :global(.main-icon) {
    margin: 0 auto;
    path {
      stroke: var(--basic-dark20);
    }
  }

  p {
    margin-top: 10px;
    font-size: 15px;
    color: var(--basic-dark40);
    text-align: center;
  }
}

.assignment_modal {
  padding: 0 20px;

  &_ticker {
    transform: translateX(-5px) !important;
    margin-bottom: 10px;
    padding: 10px 5px;
    border-radius: 16px;
    width: fit-content;
  }

  &Wrap {
    padding-bottom: 24px;

    > div {
      margin-bottom: 19px;

      > p:first-of-type {
        color: #00000066;
        font-size: 14px;
        line-height: 16.8px;
        margin-bottom: 6px;
      }
      > p:not(p:first-of-type) {
        color: #252525;
        font-family: 'GolosText-Medium', sans-serif;
        font-size: 14px;
        line-height: 16.8px;

        &:nth-of-type(2) {
          font-size: 16px;
          line-height: 19.2px;
          margin-bottom: 6px;
        }
      }
      > div {
        display: flex;
        align-items: center;

        p {
          color: #252525;
          font-family: 'GolosText-Medium', sans-serif;
          font-size: 16px;
          line-height: 19.2px;
          margin-right: 3px;

          &:first-of-type {
            color: #00000066;
          }
        }
      }
      &:last-of-type {
        margin: 0;
      }
    }
  }

  :global(.ant-slider .ant-slider-handle::after) {
    box-shadow: 0 0 0 2px #252525;
  }
  :global(.ant-slider .ant-slider-track) {
    background-color: #252525;
  }
  :global(.ant-slider .ant-slider-dot-active) {
    border-color: #252525;
  }
}

.input_relat {
  display: flex;
  margin-bottom: 32px;
  align-items: center;
  justify-content: space-between;
  div {
    min-height: 48px;
    min-width: 48px;
    background-color: #f5f6f7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input {
    border: 0;
    border-bottom: 1px solid;
    font-size: 32px;
    text-align: center;
    margin-left: 24px;
    margin-right: 24px;
    border-radius: 0;
  }
}

.flrx_bb {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    -webkit-text-decoration: underline dashed;
    text-decoration: underline dashed;
  }
  button {
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 100px;
    padding: 8px 16px;
    font-size: 10px;
    font-family: 'GolosText-Medium';
    color: rgba(37, 37, 37, 0.8);
  }
}

.unactiv_but {
  cursor: no-drop;
  opacity: 0.4;
}
.modaltextblock {
  h3 {
    color: #221f1c;
    font-family: 'GolosText-Medium';
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: normal;
  }
  p {
    color: rgba(37, 37, 37, 0.6);
    font-family: 'GolosText';
    font-size: 14px;
  }
}
.verif_bl_fl {
  height: calc(100vh - 180px);
  position: relative;
}
.verif_bl {
  width: 300px;
  margin: 0 auto;
  font-family: 'GolosText-Medium', sans-serif;
  p {
    font-size: 18px;
    text-align: center;
    color: #000000;
    white-space: nowrap;
    position: absolute;
    top: calc(50% - 80px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
  a {
    background-color: #7a24ea;
    color: #eaeaea;
    padding: 12px 56px;
    border-radius: 100px;
    font-size: 16px;
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.btn_all {
  text-align: center;
  margin: 0 auto;
  display: block;
  color: #959595;
  border-bottom: 1px dashed;
}
.nack_btn {
  display: flex;
  text-decoration: none;
  padding: 0;
  margin: 8px 0;
  div {
    margin-right: 4px;
  }
}
