@import "../../scss/variables.scss";
@import "../../scss/fonts";
@import "../../scss/mixins";

.verific_page {
  :global(.header_glob_flex) {
    padding: 0 !important;
    > div {
      margin: 0;
    }
  }
}
.siteLayout {
  width: 100%;
  padding: 60px 16px 80px 16px;
  max-width: 576px;
  margin: 0 auto;
  .link {
    color: #252525;
    border-bottom: 1px dashed;
    font-size: 14px;
    margin-left: 4px;
    font-family: "GolosText-Medium";
  }
  .link_b {
    color: rgba(0, 0, 0, 0.4);
    border-bottom: 1px dashed;
    font-size: 12px;
    margin-left: 4px;
    font-family: "GolosText-Medium";
  }
  .titleAuth {
    color: #252525;
    font-size: 24px;
    line-height: normal;
    margin-bottom: 10px;
    font-family: "GolosText-Medium";
    font-weight: 500;
  }
  .little_text {
    color: $color_text_gr1;
    font-family: "GolosText";
    font-size: 14px;
  }
  .loginForm {
    padding-top: 32px;
    .input {
      border: 1px solid rgb(0, 0, 0, 40%);
      border-radius: 50px;
    }
    .checkbox {
      color: rgb(0, 0, 0, 40%);
      :global(.ant-checkbox-checked) {
        :global(.ant-checkbox-inner) {
          background-color: #d3d3d3;
          border-color: #252525;
          :global(.ant-checkbox-inner):hover {
            :global(
                .ant-checkbox-checked:not(.ant-checkbox-disabled)
                  .ant-checkbox-inner
              ) {
              background-color: rgb(0, 0, 0, 40%) !important;
              border-color: rgb(0, 0, 0, 40%) !important;
            }
          }
          &::after {
            border-color: #252525 !important;
          }
        }
      }
    }
    :global(.ant-form-item-label) {
      margin: 0;
      padding: 0;
    }
    :global(.react-code-input) {
      input {
        &:last-child {
          margin-right: 0 !important;
        }
      }
    }
  }

  .error_block {
    border-radius: 50px;
    background: #fffbe8;
    padding: 16px 16px 16px 80px;
    margin: 24px 0;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      background: url(../../assets/images/erorimg.svg) no-repeat;
      width: 60px;
      z-index: 1;
      top: 30%;
      left: 8px;
      height: 60px;
    }
    p {
      font-size: 12px;
      font-family: "GolosText";
      color: rgba(0, 0, 0, 0.4);
      span {
        font-family: "GolosText-Medium";
      }
    }
  }
  .little_text2 {
    font-size: 14px;
    padding: 0;
    font-family: "GolosText";
    color: rgba(0, 0, 0, 0.4);
    span {
      border-bottom: 1px dashed;
    }
    a {
      text-decoration: underline;
      font-size: 12px;
      font-family: "GolosText";
      color: rgba(0, 0, 0, 0.4);
      &:hover {
        color: rgba(0, 0, 0, 0.4);
      }
    }
    &:hover {
      span {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
  .color_gr {
    font-size: 14px;
    color: #7c7c7c;
    font-family: "GolosText";
  }
  .text_grb {
    font-size: 12px;
    font-family: "GolosText";
    display: inline;
    border-bottom: 1px dashed;
    color: #7c7c7c;
  }
  .again_kod {
    font-size: 12px;
    font-family: "GolosText";
    color: #7c7c7c;
  }
  .enter_button {
    background-color: #252525;
    color: #eaeaea;
    border-radius: 50px;
    padding: 8px 16px;
    max-width: 576px;
    width: 100%;
    box-sizing: border-box;
    font-family: "GolosText-Medium";
    display: block;
    margin: 0 auto;
    &:hover,
    &:focus {
      background-color: #252525 !important;
      color: #eaeaea;
    }
  }
  .inputs {
    input {
      background: #eaeaea !important ;
    }
  }
  :global(.ant-form-item-explain-error) {
    position: absolute;
    bottom: 3px;
    font-size: 12px;
  }
  :global(.react-code-input) {
    width: 100%;
  }
}
.login_wrp {
  position: relative;
  font-family: "GolosText" !important;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 24px;
  label {
    padding: 6px 16px 0 16px;
    font-family: "GolosText-Medium";
    color: $color_text_gr !important;
    box-shadow: none !important;
    font-size: 14px;
    margin: 0;
    &::before {
      display: none !important;
    }
  }
  input {
    border-radius: 50px !important;
    background: #fff !important;
    margin: 0 2px 0 2px;
    width: calc(100% - 4px);
    border: 0 !important;
    padding: 8px 16px 8px 16px !important;
    font-family: "GolosText" !important;
    &:-webkit-autofill {
      -webkit-box-shadow: inset 0 0px 0px 20px #fff !important; /* Цвет фона */
      //background: #fff !important;
      border-radius: 50px !important;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
  :global(.ant-input-affix-wrapper) {
    background: transparent !important;
    border: 0 !important;
    padding: 0 11px 0 0px !important;
    &:focus,
    &:hover,
    &:focus-within {
      box-shadow: none !important;
    }
  }
}
.buttonBlockFixed {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: 0 20px;
  .login_form_button {
    border-radius: 50px;
    width: 100%;
    font-size: 14px;
    max-width: 576px;
    height: auto;
    display: block;
    margin: 0 auto;
    span {
      padding: 8px 16px;
    }
  }
  .buttonFixed {
    background-color: $color_text_gr2;
    color: #eaeaea;
    &:hover {
      background-color: $color_text_gr2 !important;
      color: #eaeaea !important;
    }
  }
  .button_anactive {
    background-color: #252525;
    color: #eaeaea;
    &:hover {
      background-color: #252525 !important;
      color: #eaeaea !important;
    }
  }
}
.button_save {
  display: block;
  width: 100%;
  border-radius: 24px;
  border: 0;
  background: $color_text_b !important;
  color: #fff;
  padding: 6px 16px;
  height: auto;
  font-size: 16px;
  &:hover {
    color: #fff !important;
    background: $color_text_b !important;
  }
  &.active {
    color: #fff;
    background: $color_text_b !important;
    &:hover {
      color: #fff !important;
      background: $color_text_b !important;
    }
  }
}
