@import '../../scss/variables.scss';
@import '../../scss/fonts';

.header {
  position: sticky;
  top: 0;

  padding: 0 16px;
  width: calc(100% + 32px);
  max-width: 576px;
  margin-left: -16px;

  z-index: 6;
  box-sizing: border-box;

  background-color: #f5f5f5;
}

.backButton {
  display: flex;
  align-items: center;

  .main-icon {
    width: 18px;
  }
}

.tickerTitle {
  margin-bottom: 32px;

  :global(.ticker-title-symbol) {
    font-size: 18px;
  }
}

.marketStatus {
  color: #7c7c7c;

  height: 50px;
  line-height: 46px;
  position: relative;
  display: inline-block;
  font-size: 14px;

  &Label {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 10px;
    line-height: 1.2;
    white-space: nowrap;
    font-family: 'GolosText-Medium';
    color: var(--basic-dark40);
  }
  .marketBadgeStatus1 {
    :global(.ant-badge-status-dot) {
      display: none;
    }
    :global(.ant-badge-status-text) {
      display: flex;
      align-items: center;
    }
    img {
      margin-right: 8px;
    }
  }
  .marketBadgeStatus {
    :global(.ant-badge-status-dot) {
      width: 12px;
      height: 12px;
      background-color: #d5b610 !important;
    }
    :global(.ant-badge-status-warning) {
      background-color: #d5b610;
    }
    .marketBadgeSuccess {
      color: #d5b610;
      text-decoration: underline dotted #d5b610;
    }
    .marketBadgeError {
      color: #d5b610;
      text-decoration: underline dotted #d5b610;
    }
    .marketBadgeWarning {
      color: #d5b610;
      text-decoration: underline dotted #d5b610;
    }
  }
}
.title {
  line-height: 20px;
  .titleSymbol {
    color: #252525;
    font-size: 16px;
  }
  .titleName {
    color: #a8a8a8;
    font-size: 12px;
  }
}
.price {
  margin-top: 15px;
  .priceText {
    color: #a8a8a8;
    font-size: 12px;
  }
  .pricePercent {
    margin-top: 4px;
  }
  .priceAmount {
    color: #252525;
    font-size: 24px;
    line-height: 24px;
    margin-top: 5px;
    &:global(.tickerSignalOn) {
      //background-color: rgb(0,255,196, 0.2);
      text-shadow: 0 0 20px rgb(15, 235, 239);
      -webkit-transition: text-shadow 0.5s, -webkit-transform 0.5s;
      transition: background-color 0.5s, transform 0.5s;
    }
    &:global(.tickerSignalOff) {
      background-color: transparent;
      text-shadow: 0 0 20px transparent;
      -webkit-transition: text-shadow 0.5s, -webkit-transform 0.5s;
      transition: background-color 0.5s, transform 0.5s;
    }
  }
  .priceAmount2 {
    margin-left: auto !important;
    color: #252525;
    font-size: 18px;
    line-height: 20px;
  }
  .blockChangePercent {
    .change {
      margin-right: 10px;
    }
    .changePercent {
    }
    &.pricePercentMinus {
      color: #df376a;
    }
    &.pricePercentPlus {
      color: #19e778;
    }
  }
  .blockChangePercent2 {
    font-size: 12px;
    .change {
      margin-right: 10px;
    }
    .changePercent {
    }
    &.pricePercentMinus {
      color: $red;
    }
    &.pricePercentPlus {
      color: $green;
    }
  }
}
.graph {
  margin-top: 15px;
  margin-bottom: 24px;
}
.newsBlock {
  padding-bottom: 100px;

  .newsBlockTitle {
    font-size: 18px;
    color: #252525;
    padding: 25px 0 15px;
  }
  .butMoreNews {
    margin: 15px 0 15px;
  }
}
.tabs_analitycs {
  :global(.ui-tabs) {
    margin-bottom: 16px;
    background-color: var(--secondary-light);
  }
  :global(.ui-tabs__item > .ui-clickable) {
    padding: 8px 16px;
    font-size: 10px;
    font-family: 'GolosText-Semibold';
    text-transform: uppercase;
  }
  :global(.ui-tabs__item + .ui-tabs__item) {
    margin-left: 24px;
  }

  .tabs_wrp {
    padding: 16px;
    background: #fff;
    box-sizing: border-box;
    border-radius: 20px;
    margin-bottom: 24px;

    width: 100%;
    //margin-left: -9px;

    :global(.ant-tabs-nav-list) {
      width: 100%;
      background: #f5f6f7 !important;
      margin: 0 auto;
      border-radius: 50px;
      box-sizing: border-box;
      padding: 8px 16px;
      :global(.ant-tabs-tab) {
        flex: 1;
        padding: 8px 0;
        font-size: 10px;
        font-family: 'GolosText-Medium';
        text-align: center;
        color: #252525;
        text-transform: uppercase;
        div {
          width: 100%;
          padding: 0px 16px;
          &:focus {
            color: #252525;
          }
        }
      }
      :global(.ant-tabs-tab.ant-tabs-tab-active) {
        background: #252525;
        color: #fff;
        font-family: 'GolosText-Semibold';
        border-radius: 24px;
        div {
          color: #fff;
          padding: 0px 16px;
          text-shadow: none;
        }
      }
    }
    :global(.ant-tabs-ink-bar) {
      display: none !important;
    }

    .tabs_trading_tb {
      margin-bottom: 16px;

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          font-size: 13px;
          display: block;
          padding: 4px 0;
          flex: 1;
          color: rgba(0, 0, 0, 0.4);
          font-family: 'GolosText-Medium';
        }
        span {
          font-family: 'GolosText-Medium';
          color: #000;
          display: inline-block;
          text-align: right;
        }
      }
    }
    .tabs_titile {
      font-size: 14px;
      font-family: 'GolosText-Medium';
      color: #252525;
      display: inline-block;
      //      border-bottom: 1px dashed;
      margin-bottom: 5px;
    }
    .tabs_trading_tb1 {
      .tabs_text {
        font-size: 14px;
        display: block;
        padding: 4px 0;
        width: 100%;
        color: rgba(0, 0, 0, 0.4);
        font-family: 'GolosText';
      }
      a {
        color: rgba(0, 0, 0, 0.4);
        font-size: 13px;
        font-family: 'GolosText-Medium';
        text-align: right;
        text-decoration: underline;
        display: block;
      }
    }
  }
  .error_block {
    border-radius: 50px;
    display: block;
    background: #fffbe8;
    padding: 16px 16px 16px 80px;
    margin: 32px 0 24px 0;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      background: url(../../assets/images/erorimg.svg) 100% 50% no-repeat;
      width: 60px;
      z-index: 1;
      top: 0;
      left: 8px;
      height: 100%;
    }
    p {
      font-size: 12px;
      font-family: 'GolosText';
      color: rgba(0, 0, 0, 0.4);
      span {
        font-family: 'GolosText-Medium';
      }
    }
  }
  .tabs_trading_bl {
    :global(.ant-tabs-nav-list) {
      background: transparent !important;
      margin: 0;
      border-radius: 0px;
      box-sizing: border-box;
      border-bottom: 2px solid rgba(0, 0, 0, 0.4);
      padding: 0px;
      :global(.ant-tabs-tab) {
        font-size: 14px;
        font-family: 'GolosText';
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.4);
        text-transform: none;
        div {
          text-shadow: none;
          padding: 0px 0px;
          &:focus {
            color: rgba(0, 0, 0, 0.4);
          }
        }
      }
      :global(.ant-tabs-tab.ant-tabs-tab-active) {
        color: #252525;
        background: transparent;
        box-shadow: none;
        font-family: 'GolosText';
        box-sizing: border-box;
        border-radius: 24px;
        div {
          text-shadow: none;
          color: #252525;
          padding: 0px 0px;
        }
      }
    }
    :global(.ant-tabs-ink-bar) {
      background: #252525;
      bottom: -2px;
      display: block !important;
    }
  }
}

.tabs_hidden {
  :global(.ant-tabs-nav) {
    display: none;
  }
}

.fixed_but_trade {
  position: sticky;
  bottom: 24px;
  z-index: 2;
  height: 0px;

  button {
    width: 100%;
    max-width: 544px;
    height: auto;
    display: block;
    font-family: 'GolosText-Medium';
    margin: 0 auto;
  }
}

.graph_blocks {
  svg {
    text {
      white-space: pre-wrap;
      line-height: normal;
    }
  }
  @media screen and (max-width: 400px) {
    height: 250px !important;
  }
}

.fin_block {
  .tabs_titile {
    margin-bottom: 10px !important;
  }

  & + .fin_block {
    margin-top: 15px;
  }
}

.fin_list {
  display: flex;
  align-items: stretch;
  column-gap: 10px;

  :global(.ui-icon-button) {
    height: auto;
    border-radius: 20px;
    border-color: var(--basic-dark10);
  }
}

.fin_item {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 10px 15px;
  border: 1px solid var(--basic-dark10);
  border-radius: 20px;

  &_label {
    margin-bottom: 5px;
  }
  &_price {
    font-size: 16px;
    span {
      font-weight: 600;
    }
  }
}
.modaltextblock {
  padding: 0 16px;
  div {
    border-bottom: 1px solid #E4E5E6A1;
    padding-bottom: 4px;
    margin-bottom: 16px;
    h3 {
      color: #221f1c;
      font-family: 'GolosText-Medium';
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 8px;
    }
    p {
      font-family: 'GolosText', sans-serif;
      font-size: 14px;
      &:first-of-type {
        color: #252525CC;
        margin-bottom: 8px;
      }
      &:nth-of-type(2) {
        color: var(--basic-dark60);
      }
      &:nth-of-type(3) {
        color: #d5b610;
      }
    }
  }
}
