.TabsSymbol {
  // @use-gestures
  touch-action: pan-y;

  .tabs {
    user-select: none;
    align-items: flex-start;
    position: relative;
    justify-content: center;
    display: flex;
    margin: 0 auto;
    padding: 8px 0px 0 0px;
    box-sizing: border-box;
    overflow-x: hidden;
    scrollbar-width: thin;
    white-space: nowrap;
    width: auto;

    .tab {
      color: #939494;
      cursor: pointer;
      align-items: center;
      display: flex;
      font-family: "GolosText-Medium";
      font-size: 14px;
      justify-content: center;
      margin-right: 16px;
      padding: 8px 8px;
      position: relative;

      &.active {
        color: var(--basic-dark);
        border-bottom: 2px solid var(--basic-dark);
        svg {
          path {
            fill: var(--basic-dark);
          }
        }
      }

      svg {
        width: 20px;
        margin-right: 4px;

        path {
          fill: #939494;
        }
      }
    }
    &::before {
      content: "";
      border-bottom: 2px solid #d3d3d3;
      width: 100%;
      position: absolute;
      bottom: 0;
      display: block;
    }
  }

  &Wrap {
    // padding: 0 15px;
    // width: 100vw;
    width: 100%;
    overflow: hidden;
    // transform: translateX(-15px);

    & > div {
      display: flex;
      height: 100%;
    }
  }

  &List {
    flex-shrink: 0;
    width: 100%;
    margin-left: 17px;

    &:first-child {
      margin-left: 0;
    }
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      display: none;
      width: 0;
      height: 0;
    }
    *::-webkit-scrollbar-track {
      background: transparent;
    }
    *::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 5px;
    }
  }

  &MoreBtn {
    margin-top: 5px;
    display: block;
    margin-left: auto;

    border-style: dashed;
    padding-bottom: 2px !important;
    color: var(--basic-dark40) !important;
    border-color: var(--basic-dark40) !important;
  }
}
