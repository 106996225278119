@import "../../scss/variables.scss";
@import "../../scss/fonts";
@import "../../scss/mixins";

.back_bl {
  width: 24px;
  color: #7c7c7c;
  display: flex;
  font-size: 14px;
  font-family: "GolosText-Medium";
  font-weight: 500;
  align-items: center;
}
