@import '../../scss/variables.scss';
@import '../../scss/fonts';
@import '../../scss/mixins';

.bottombar {
  position: fixed;
  z-index: 11;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  border-radius: 70px;
  border: 1px solid var(--Border-Cards-1, #e1e2e3);
  background: linear-gradient(180deg, #d3d3d3 0%, rgba(211, 211, 211, 0.68) 100%);

  box-shadow: 0px 15px 100px 0px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);

  transition: 0.2s ease;
  &.hidden {
    transform: translateX(-50%) translateY(calc(100% + 50px));
    pointer-events: none;
  }

  animation: fromHidden 0.6s ease 1;
  @keyframes fromHidden {
    from {
      transform: translateX(-50%) translateY(calc(100% + 50px));
    }
  }

  &-wrap {
    display: flex;
    padding: 5px;
  }

  &__item {
    position: relative;
    width: 60px !important;
    height: 60px !important;
    overflow: hidden;
    border-radius: 60px;

    .ui-clickable-ripple div {
      background-color: var(--basic-accent) !important;
    }

    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    background: #3d3d3e;

    &-fill {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-color: var(--basic-accent);

      transition: 0.3s ease;

      transform-origin: center;
      transform: scale(0);

      @keyframes BBF_hide_left {
        from {
          left: 0%;
          transform: scale(1, 0.95);
        }
        to {
          left: -100%;
          transform: scale(0, 0);
        }
      }
      @keyframes BBF_hide_right {
        from {
          left: 0%;
          transform: scale(1, 0.95);
        }
        to {
          left: 100%;
          transform: scale(1, 1);
        }
      }

      @keyframes BBF_show_left {
        from {
          left: -100%;
          transform: scale(0, 0);
        }
        to {
          left: 0%;
          transform: scale(1, 1);
        }
      }
      @keyframes BBF_show_right {
        from {
          left: 100%;
          transform: scale(0, 0);
        }
        to {
          left: 0%;
          transform: scale(1, 1);
        }
      }
    }

    .main-icon {
      position: relative;
      width: 26px;
      height: 26px;
      z-index: 2;

      path {
        transition: 0.4s ease;
        fill: #e3e6e8;
      }
    }

    &.active {
      .main-icon path {
        fill: #fff !important;
      }
    }
  }
}

.side_bar_bottom {
  width: 268px;
  border-radius: 70px;
  background: linear-gradient(180deg, #d3d3d3 0%, rgba(211, 211, 211, 0.68) 100%);
  box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.4);
  z-index: 10;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 32px;
  margin: 0 auto;

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .button {
      margin-right: 6px;
      display: block;
      margin-top: 6px;
      padding: 0;
      /*width: 33%;
      //width: auto;
      cursor: pointer;
      border: 0;
      height: 100%;
      padding: 8px 0;
   
      text-align: center;
      background: none;
      color: $gray_text;
      font-size: 14px;*/

      img {
        width: 60px;
        height: 60px;
      }
      .icon {
        filter: saturate(5%);
      }
      span {
        display: block;
        margin: 0 0 0px 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
