.list {
  :global(.ticker-order-card) {
    // margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.filterBtn {
  width: 0px;
  height: 0px;

  margin-left: auto;
  transform: translateY(-21px);

  :global(.ui-icon-button) {
    transform: translate(-100%, -100%) !important;
  }
}