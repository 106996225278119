.header {
  padding: 10px 0;
  position: relative;
  display: flex;
  align-items: center;
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: var(--basic-dark);
  font-size: 18px;
  font-weight: 500;
}

.wrap {
  width: 100%;
  overflow: hidden;
}

.screenWrap {
  display: flex;
  transition: 0.4s ease;
}

.screen {
  flex-shrink: 0;
  width: 100%;
}

.screenHead {
  display: flex;
  align-items: center;

  & > div {
    &:nth-child(1) {
      font-size: 13px;
      color: var(--basic-dark40);
    }
    &:nth-child(2) {
      width: 150px;
      display: flex;
      transform: translateX(0px);

      p {
        flex: 1;
        font-size: 10px;
        text-align: center;
        white-space: nowrap;
      }
    }
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid var(--basic-dark10);
  cursor: pointer;

  &_label {
    width: 140px;
    color: var(--basic-dark);

    transition: 0.5s ease;
    overflow: hidden;

    & > div {
      width: 140px;
    }
  }

  &_chart {
    margin: 0 10px;
    flex: 1;
    display: flex;
    align-items: end;
    column-gap: 5px;
    transform: translateY(-3px);

    div {
      position: relative;
      flex: 1;
      min-height: 2px;
      border-radius: 3px;
      background-color: var(--basic-dark20);

      transition: 0.5s ease;

      p {
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 100%;
        color: var(--basic-dark);
        font-size: 12px;
        white-space: nowrap;

        transition: 0.2s ease;
      }
    }
  }

  &_price {
    width: 80px;
    text-align: right;
    margin-left: auto;

    &_value {
      font-size: 14px;
      font-weight: 500;
      color: var(--basic-dark);
    }

    :global(.price-changing) {
      margin-left: auto;
    }
  }
}

.tabs {
  margin-bottom: 15px;
  display: flex;
  column-gap: 10px;

  :global(.ui-button) {
    font-size: 13px;
    padding: 8px 0;
  }
}

.screenEmpty {

  margin-top: 30px;

  :global(.main-icon) {
    margin: 0 auto;
    width: 80px;
    path {
      stroke-width: 1;
      stroke: var(--basic-dark40);
    }
  }

  h4 {
    margin-top: 10px;
    font-weight: 500;
    text-align: center;
    color: var(--basic-dark40);
  }
}
