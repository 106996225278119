@import '../../scss/variables.scss';

.item_block {
  display: block;
  padding: 12px 16px;
  cursor: pointer;
  margin-bottom: 16px;
  border-radius: 20px;
  background: #fff;
  position: relative;
  // border: 1px solid var(--basic-dark10);


  &__datet {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    p {
      font-size: 12px;
      padding: 0;
      margin-right: 8px;
      color: var(--basic-dark40);
    }
  }
  &__tickers {
    margin-top: 4px;
    margin-bottom: 4px;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    ul {
      padding: 0;
      white-space: nowrap;
      margin: 0;
      li {
        list-style-type: none;
        padding: 0;
        margin-right: 8px;
        scroll-snap-align: start;
        display: inline-block;
      }
    }
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

.item_block__bl {
  display: flex;
  margin-bottom: 8px;
  align-items: center;

  :global(.image-preview) {
    margin-right: 8px;
  }
  p {
    font-size: 14px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    line-height: 20px;
    color: $color_text_b;
  }
}
.news_fon {
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  height: 180px;
  margin-bottom: 16px;
}
.tit_categ {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.35);
}

.tit_categ_big {
  font-family: 'GolosText-Medium';
  font-size: 14px;
  color: $color_text_b;
}
.text_main {
  margin-top: 24px;
  // max-height: 40vh;
  // overflow-y: auto;
  color: var(--basic-dark40);
  font-size: 13px;
  line-height: 1.5;
  // white-space: pre-line;

  p + p {
    margin-top: 20px;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 24px 0;
  }

  figure {
    margin-top: 16px;

    & + p {
      margin-bottom: 16px;
    }
  }

  t {
    color: var(--misk-alert);
  }

  img {
    border-radius: 8px;
    width: 100% !important;
    height: auto !important;
  }

  table {
    margin: 10px 0;
    display: block;
    width: 100%;
    overflow-x: auto;

    thead {
      border-radius: 8px;
      background-color: var(--basic-light);

      th {
        border-radius: 16px;
        padding: 10px 15px;
        white-space: nowrap;
      }
    }

    tbody {
      tr td {
        padding: 2px;
      }
    }
  }

  a {
    color: inherit;
    pointer-events: none;
  }

  :global(.wp-block-embed-twitter) {
    display: none;
  }
}
.auto_height {
  :global(.border_color) {
    height: 0 !important;
  }
}

.img_large {
  max-width: 100%;
  border-radius: 8px 8px 0 0;
}

.news_source {
  border-top: 1px solid var(--basic-dark20);
  padding-top: 10px;
}
.news_disclaimer {
  margin-top: 8px;
  font-size: 10px;
  font-style: italic;
  padding-bottom: 24px;
}
