.modalManager {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;
  z-index: 999;
  overflow: hidden;

  pointer-events: none;

  &Active {
    pointer-events: all;

    .overlay {
      opacity: 1;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.3);

  transition: 0.3s ease;
  opacity: 0;
}

.basicModal {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  width: 100%;
  max-width: 576px !important;

  transition: 0.6s cubic-bezier(0.16, 1, 0.3, 1);

  &Body {
    position: relative;
    min-height: 100px;
    background-color: white;

    &::after {
      position: absolute;
      content: '';
      top: 100%;
      left: 0;
      width: 100%;
      background-color: white;
      height: 30px;
    }
  }

  &Handle {
    position: relative;
    width: 100%;
    height: 40px;
    border-radius: 24px 24px 0 0;
    background-color: white;

    &::after {
      position: absolute;
      content: '';
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: white;
      z-index: 2;
    }

    touch-action: none;
    width: 100%;
    transition: 0.3s ease;

    div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 4px;
      border-radius: 4px;
      background-color: var(--basic-dark);
      transition: 0.3s ease;
      filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.25));
    }

    cursor: pointer;
    &:active {
      border-radius: 30px 30px 0 0;

      div {
        transform: translate(-50%, -50%) scale(0.9);
      }
    }
  }

  &Opened {
    transform: translateX(-50%) translateY(0%);
  }
}

.modalConfirm {
  padding: 24px 10px;
  padding-top: 0;
}

.confirmBlock {
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  border-top: 1px solid var(--basic-dark20);

  transition: 0.3s ease;
  transform: translateY(100%);

  .confirmTitle {
    color: var(--basic-dark);
    padding: 16px;
    text-align: center;
  }

  &Active {
    transform: translateY(0%);
  }
}

.helperTitle {
  color: #221f1c;
  font-family: 'GolosText-Medium';
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: normal;
}

.helperText {
  color: rgba(37, 37, 37, 0.6);
  font-family: 'GolosText';
  font-size: 14px;

  b {
    color: var(--basic-dark);
    font-weight: 500;
    font-family: "GolosText-Medium";
  }

  p {
    margin-bottom: 8px;
  }
}

.confirmModal {
  padding-bottom: 24px;

  p {
    color: #221F1C;
    font-size: 16px;
    text-align: center;
    padding: 10px;
    margin-bottom: 8px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    button {
      width: 106px;
      background-color: var(--basic-dark);
      border-radius: 50px;
      color: var(--basic-light);
      font-family: "GolosText-Medium", sans-serif;
      font-size: 14px;
      margin: 0 14px;
      padding: 10px 30px;

      &:first-of-type {
        background: transparent;
        border: 1px solid var(--basic-dark);
        color: var(--basic-dark);
      }
    }
  }
}

.PDFModal {

  >div:last-of-type {
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    bottom: 10px;
    padding: 0 10px;
  }
}