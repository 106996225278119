.ticker-title {
  flex: 1;
  display: flex;
  align-items: center;

  &-logo {
    position: relative;
    flex-shrink: 0;
    border-radius: 100px;
    background-size: cover;
    background-position: center;
    overflow: hidden;

    span {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 100%;
      width: 100%;
      height: 100%;

      text-align: center;

      font-size: 14px;
      font-family: "GolosText-Bold";
      color: var(--basic-light);
    }

    &::before {
      position: absolute;
      content: "";
      border-radius: 100%;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      background: linear-gradient(
        152deg,
        rgba(217, 217, 217, 0.32) 14.34%,
        rgba(217, 217, 217, 0) 86.68%
      );
      //z-index: 1;
    }
  }

  &-info {
    margin-left: 10px;
  }

  &-symbol {
    text-align: left;
    font-size: 17px;
    font-family: "GolosText-Medium";
    font-weight: 500;
    color: var(--basic-dark);
  }

  &-desc {
    margin-top: 3px;
    font-size: 12px;

    color: var(--basic-dark40);
    font-family: "GolosText";
    font-weight: 400;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* number of lines to show */

    // overflow: hidden;
    // text-overflow: ellipsis;
    // // text-transform: uppercase;
    // white-space: nowrap;
    // width: 100%;
  }
}
