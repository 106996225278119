.m-signature-pad {
    position: relative;
    width: 100%;
    height: 100%;
}

.m-signature-pad--body
canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}