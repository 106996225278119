.ui-number-input {
  position: relative;

  input {
    width: 100%;
    flex: 1;
    padding: 8px 0;
    text-align: center;
    background: transparent;

    appearance: textfield;
    border-radius: 0;
    font-size: 30px;
    font-family: inherit;

    border: none;
    border-bottom: 1px solid var(--basic-dark);

    &:focus {
      box-shadow: none;
    }
  }
}
