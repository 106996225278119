@import '../../scss/variables.scss';
@import '../../scss/mixins';

.fixed_bl {
  position: fixed;
  top: 0;
  width: 100%;
  background: #f5f6f7;
  z-index: 2;
}
.account_wp {
  background: #ffffff;
  //height: 203px;
  padding: 16px;
  box-sizing: border-box;
  display: block;
  border-radius: 30px;
  border: 1px solid rgba(228, 229, 230, 0.63);
  width: 100%;
  &__title {
    color: $color_text_gr2;
    text-align: center;
    font-size: 14px;
    font-family: 'GolosText-Medium';
  }
  &__number {
    margin: 0 auto;
    margin-top: 4px;
    color: $color_text_gr;
    text-align: center;
    padding-top: 4px;
    font-size: 16px;
    line-height: 16px;
    font-family: 'GolosText';
  }
  &__price {
    margin: 0 auto;
    font-size: 28px;
    text-align: center;
    display: block;
    line-height: normal;
    margin-top: 5px;
    color: $color_text_b;

    span {
      line-height: 1;
    }
  }
  &__buttons {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .link {
      margin-right: 8px;
      flex: 1;
      font-size: 11px;
      text-align: center;
      border-radius: 24px;
      padding: 14px 4px;
      color: $color_text_b;
      box-sizing: border-box;
      border: 1px solid $color_text_gr;
      img {
        margin: 0 auto 8px;
        display: block;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    &_popol {
      background: $color_text_b;
      color: #fff !important;
      border: 0 !important;
    }
    a,
    &_link {
      display: inline;
      font-size: 12px;
      font-family: 'GolosText-Medium';
      border-bottom: 1px dashed;
      color: #7e7e7f;
      text-decoration: none;
    }
    > div {
      > span {
        display: block;
        text-align: center;
        padding: 0;
        margin-top: 4px;
        width: auto;
      }
    }
  }
}

.btn_all {
  text-align: center;
  margin: 0 auto;
  display: block;
  color: #959595;
  border-bottom: 1px dashed;
}

.dis_but {
  opacity: 0.2;
}

.commis {
  text-align: center;
  font-size: 14px;
  color: #d5b610;
  font-family: 'GolosText';
}
.block_one_btn {
  background: #fff;
  margin-top: 24px;
  display: flex;
  font-size: 16px;
  font-family: 'GolosText-Medium';
  align-items: center;
  padding: 16px;
  color: $color_text_gr;
  border-radius: 20px;
  img {
    margin-right: 8px;
  }
  &:hover {
    color: $color_text_gr;
  }
}
.tabs_account {
  .title {
    font-size: 16px;
    font-family: 'GolosText-Medium';
    font-weight: 500;
    color: $color_text_b;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .output_bl_item {
    border-radius: 20px;
    box-sizing: border-box;
    padding: 16px;
    border: 1px solid rgba(228, 229, 230, 0.63);
    background: #fff;
    margin-bottom: 10px;

    animation: fadeOut 0.3s ease 1;
    @keyframes fadeOut {
      from {
        opacity: 0;
        transform: scale(0.95);
      }
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        margin-right: 8px;
      }
      p {
        font-size: 14px;
        font-family: 'GolosText-Medium';
        font-weight: 500;
        color: $color_text_b;
      }
      span {
        color: $color_text_gr;
        font-size: 12px;
        font-family: 'GolosText';
      }
      b {
        font-size: 13px;
        font-family: 'GolosText';
        font-weight: 400;
      }
    }
  }
  .filter {
    // border-radius: 100px;
    // background: $color_text_b;
    // color: #eaeaea;
    // font-size: 12px;
    // padding: 9px;
    // text-align: center;
    // box-sizing: border-box;
    // height: 34px;
    // width: 34px;
    // margin-right: 8px;
    // cursor: pointer;
  }
  .filterChipWrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 16px;
    > div {
      margin-right: 4px;
      margin-top: 4px;
    }
  }
}

.one_accounts {
  border-radius: 30px;
  border: 1px solid rgba(228, 229, 230, 0.63);
  background: #fff;
  padding: 24px 16px;
  text-align: center;
  position: relative;

  p {
    font-family: 'GolosText';
    font-size: 13.5px;
    font-style: normal;
    color: $color_text_gr;
    font-weight: 400;
    line-height: normal;
  }
  .one_tit {
    font-family: 'GolosText-Medium';
    font-size: 14px;
    margin-bottom: 4px;
  }
  .one_num {
    font-size: 16px;
  }
  .one_link {
    display: inline-block;
    text-decoration: none;
    font-family: 'GolosText';
    border-bottom: 1px dashed;
    margin-top: 4px;
    color: #959595;
    cursor: pointer;
  }
  span {
    font-size: 32px;
    color: $color_text_b;
  }
}
.arrow_bl {
  &::after {
    content: url(../../assets/images/between.svg);
    position: absolute;
    height: 20px;
    bottom: -8px;
    left: 50%;
    z-index: 1;
    margin-left: -20px;
    height: 20px;
  }
}

.transfer_money {
  input {
    background: transparent;
    border-top: 0;
    border-left: 0;
    border-radius: 0;
    font-family: 'GolosText';
    padding: 8px 0;
    text-align: center;
    font-size: 32px;
    border-right: 0;
    border-bottom: 2px solid $color_text_b;
    &:hover {
      border-bottom: 2px solid $color_text_b;
    }
  }
}
.titile {
  font-size: 14px;
  font-family: 'GolosText-Medium';
  font-weight: 500;
  color: #221f1c;
  margin-top: 8px;
  margin-bottom: 16px;
}
.block_valute1 {
  background: #fff;
  padding: 16px;
  border-radius: 24px;
  margin-bottom: 24px;
  :global(.ant-form-item) {
    margin-bottom: 8px;
  }
  :global(.ant-col) {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  :global(.ant-select-selector) {
    background: transparent !important;
    font-size: 12px !important;
    padding: 8px 0 !important;
    font-family: 'GolosText-Medium';
    color: #959595 !important;
    border-bottom: 1px solid #eaeaea !important;
    border-left: 0 !important;
    border-radius: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
    box-shadow: none !important;
    input {
      border: 0 !important;
    }
  }
  label {
    font-size: 12px !important;
    font-family: 'GolosText' !important;
  }

  input {
    font-size: 12px;
    padding: 8px 0;
    color: #959595;
    font-family: 'GolosText-Medium';
    background: transparent;
    border-bottom: 1px solid #eaeaea !important;
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    border-top: 0;
    &:hover {
      border-color: #eaeaea !important;
    }
  }
}
.button_trans {
  display: block;
  background: $color_text_b;
  color: #fff;
  font-family: 'GolosText-Medium';
  text-align: center;
  font-size: 16px;
  width: 100%;
  border-radius: 100px;
  margin-top: 24px;
  padding: 12px 16px;
  box-sizing: border-box;
}
.account_page_sl {
  :global(.container) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  :global(.slick-dots .slick-active button) {
    background: #7a24ea !important;
  }
  :global(.slick-dots li button) {
    width: 8px;
    height: 8px;
    background: #e3e6e8;
    border-radius: 8px;
  }
  :global(.slick-dots li) {
    margin-inline: 2px;
  }
  :global(.slick-list, .slick-slider) {
    height: 100%;
  }
  :global(.slick-slider) {
    height: 100%;
  }
  :global(.ant-carousel) {
    height: 100%;
  }
  :global(.slick-dots) {
    bottom: -20px !important;
  }
}
.account_page {
  :global(.container2) {
    padding-bottom: 0 !important;
  }
  // min-height: 00px;

  .add_balance_title {
    font-size: 20px;
    text-align: center;
    font-family: 'GolosText-Medium';
    font-weight: 500;
    color: #252525;
    margin-bottom: 16px;
  }

  .block_valute {
    margin-top: 45px;

    :global(.ant-input-number-input-wrap) {
      background: transparent !important;
      border: 0;
      text-align: center;
    }
    :global(.ant-input-number) {
      background: transparent !important;
      font-size: 32px;
      border-bottom: 1px solid #252525;
      text-align: center;
      margin: 0 auto;
      border-radius: 0;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      display: block;
      width: 80%;
      input {
        text-align: center;
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }
      &:focus {
        box-shadow: none;
      }
      :global(.ant-input-number-handler-wrap) {
        display: none;
      }
    }
    :global(.ant-input-number-focused) {
      box-shadow: none !important;
    }

    .buttonBlockFixed {
      position: fixed;
      bottom: 8px;
      left: 0;
      z-index: 2;
      width: 100%;
      margin-bottom: 8px !important;
      padding: 0 16px;
      .add_balance {
        color: #eaeaea;
        border-radius: 100px;
        width: 100%;
        height: auto;
        background: #7a24ea;
        span {
          font-size: 16px;
          font-family: 'GolosText-Medium';
          padding: 8px 0;
        }
      }
      button {
        border-radius: 50px;
        width: 100%;
        font-size: 14px;
        max-width: 500px;
        height: auto;
        display: block;
        margin: 0 auto;
      }
    }
    button {
      background-color: #221f1c;
    }
  }

  .between {
    margin: -6px auto;
    width: 40px;
    height: 40px;
    display: block;
    position: relative;
    z-index: 1;
  }
}
.buttonBlockFixed {
  position: fixed;
  bottom: 8px;
  left: 0;
  z-index: 2;
  width: 100%;
  margin-bottom: 8px !important;
  padding: 0 16px;
  box-sizing: border-box;

  button {
    border-radius: 50px;
    width: 100%;
    font-size: 14px;
    border: 0;
    max-width: 500px;
    height: auto;
    display: block;
    margin: 0 auto;
    padding: 8px 0;
    span {
      font-family: 'GolosText-Medium';
      font-size: 14px;
    }
    &:hover {
      border-color: #221f1c !important;
      color: #fff !important;
      span {
        color: #fff;
      }
    }
  }
}

.tabl_bl {
  .tabl_bl_one {
    margin-bottom: 20px;
    span {
      font-size: 16px;
      color: $color_text_b;
      font-family: 'GolosText-Medium';
    }
    p {
      font-size: 14px;
      font-family: 'GolosText';
    }
  }
  button {
    border-radius: 100px;
    padding: 8px 40px;
    background: #eaeaea;
    width: 100%;
    margin: 16px 0;
    font-size: 14px;
    font-family: 'GolosText-Medium';
    color: $color_text_b;
  }
}

.modal_history {
  padding: 0 16px;
  .flex {
    padding-top: 5px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    img {
      margin-right: 16px;
      width: 40px;
      height: 40px;
      display: block;
    }
    p {
      font-size: 14px;
      color: $color_text_b;
      font-family: 'GolosText-Medium';
    }
    span {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.4);
      font-family: 'GolosText';
    }
  }
  .tabl_bl {
    overflow-y: auto;
    height: auto;
    max-height: calc(100vh - 200px);
  }
}

.filter_form {
  padding: 0;
  :global(.ant-picker) {
    width: 48%;
    color: #00000066;
    font-family: 'GolosText-Medium';
    font-size: 12px;
    height: auto;
    padding: 0px 16px 0 0px;
    border-radius: 100px;
    border: 1px solid #00000066;
    input {
      padding: 11px 0 11px 14px;
      border: 0 !important;
      &:hover,
      &:focus {
        outline: none;
      }
    }
    &:hover {
      border-color: $border_color;
      outline: none;
    }
  }

  .flex_bl {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .filter_block {
      background: transparent;
      padding: 0;
    }
    .but_add {
      svg {
        height: 16px;
        width: 16px;
        .color {
          fill: $yellow_color;
        }
      }
    }
    input {
      width: 48%;
      font-family: 'GolosText-Medium';
      font-size: 16px;
      padding: 8px 16px;
      border-radius: 100px;
      border: 1px solid 1px solid rgba(0, 0, 0, 0.4);
    }
    .check_group {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }
    .label_form {
      font-size: 14px;
      color: #252525;
      font-family: 'GolosText-Medium';
    }
  }
  .clear {
    text-transform: capitalize;
    font-family: 'GolosText-Medium', sans-serif;
    font-size: 10px;
    color: #00000066;
    text-decoration: underline dotted;
    cursor: pointer;
  }
}
.but_appl {
  border-radius: 100px;
  padding: 16px 0px;
  background: #252525;
  width: 100%;
  display: block;
  margin: 16px 0 0 0;
  font-size: 14px;
  font-family: 'GolosText-Medium';
  color: #fff;
}

.inputLabel {
  font-family: 'GolosText-Medium';
  display: block;
  width: 100%;
  font-size: 16px !important;
  text-align: center;
}
.requisites {
  border-radius: 24px;
  background: #fff;
  padding: 8px 16px 16px 16px;
  margin-bottom: 32px;
  > div {
    border-bottom: 1px solid #eaeaea;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 8px;
    padding-top: 8px;
    span {
      color: #959595;
      font-family: 'GolosText-Medium';
      font-size: 11px;
    }
    p {
      color: #221f1c;
      font-family: 'GolosText';
      font-size: 12px;
    }
  }
}

.titl_lit {
  color: #221f1c;
  font-family: 'GolosText-Medium';
  font-size: 16px;
  margin-bottom: 16px;
}
.tabs1 {
  margin-bottom: 16px;

  .buttons_gr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    a {
      flex: 48.5%;
      border-radius: 12px;
      margin-bottom: 8px;
      box-sizing: border-box;
      padding: 16px;
      margin-right: 8px;
      text-decoration: none;
      max-width: 50%;
      display: block;
      position: relative;
      &:nth-child(2n) {
        margin-right: 0;
      }
      img {
        height: 16px;
        margin-bottom: 8px;
        display: block;
      }
      span {
        display: block;
        font-size: 13px;
        font-family: 'GolosText';
      }
      &.halyk {
        background: #ffffff url(../../assets/images/halyk_fon.svg) no-repeat 100% 100%;
        span {
          color: #221f1c;
        }
      }
      &.centercredit {
        background: #fff url(../../assets/images/centercredit_fon.svg) no-repeat 100% 100%;
        span {
          color: #221f1c;
        }
      }
      &.centercredit {
        background: #fff url(../../assets/images/centercredit_fon.svg) no-repeat 100% 100%;
        span {
          color: #221f1c;
        }
      }
      &.jusan {
        background: #fff url(../../assets/images/jusanfon.png) no-repeat 100% 100%;
        span {
          color: #221f1c;
        }
      }
      &.forte {
        background: #fff url(../../assets/images/firte_fon.png) no-repeat 100% 100%;
        span {
          color: #221f1c;
        }
      }
      &.bereke {
        background: rgba(2, 61, 255, 0.78) url(../../assets/images/bereke_fon.svg) no-repeat 100%
          100%;
        span {
          color: #fff;
        }
      }
      &.eub {
        background: #fff url(../../assets/images/euro_fon.svg) no-repeat 100% 100%;
        span {
          color: #221f1c;
        }
      }
      &.dr {
        background: #fff;
        span {
          color: #221f1c;
        }
        p {
          font-family: 'GolosText-Medium';
          font-size: 14px;
          color: #221f1c;
        }
      }
    }
  }
  .button_bl {
    background: #221f1c;
    color: #eaeaea;
    font-size: 14px;
    border-radius: 100px;
    padding: 8px;
    //width: 100%;
    //position: fixed;
    //bottom: 8px;
    //left: 0;
  }
}
.vivod_bl {
  background: #fff;
  padding: 16px;
  border-radius: 16px;
  .vivod_bl_top {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    flex-direction: row;
    p {
      font-family: 'GolosText-Medium';
      font-size: 14px;
      margin-left: 16px;
      color: #221f1c;
      span {
        display: block;
        font-family: 'GolosText';
        font-size: 12px;
        color: #959595;
      }
    }
  }
  .vivod_item {
    margin-bottom: 16px;
    span {
      display: block;
      font-family: 'GolosText';
      font-size: 14px;
      color: #959595;
    }
    p {
      font-family: 'GolosText-Medium';
      font-size: 16px;
      color: #221f1c;
      span {
        display: inline-block;
        font-size: 16px;
        font-family: 'GolosText-Medium';
      }
      svg {
        width: 9px !important;
      }
    }
  }
  .lit_text {
    color: #959595;
    font-family: 'GolosText';
    font-size: 12px;
    display: block;
    line-height: normal;
  }
}
.one_links {
  cursor: pointer;
}
.accounts_bb {
  > p {
    font-size: 16px;
    color: #959595;
    font-family: 'GolosText-Medium';
    margin-bottom: 8px;
  }
  .one_account {
    border-bottom: 1px solid #e1e2e3;
    padding: 8px 0;
    p {
      font-size: 14px;
      color: #000;
      font-family: 'GolosText-Medium';
    }
  }
}
.link_bl {
  display: flex;
  align-items: center;
  font-family: 'GolosText-Medium';
  color: #7a24ea;
  cursor: pointer;
  margin-bottom: 16px;
  font-size: 16px;
  img {
    margin-right: 8px;
  }
}
.error_block {
  border-radius: 24px;
  display: block;
  background: #fffbe8;
  padding: 8px 20px;
  margin: 0px 0 24px 0;
  position: relative;
  //&::after {
  //  content: '';
  //  position: absolute;
  //  background: url(../../assets/images/erorimg.svg) 100% 50% no-repeat;
  //  width: 60px;
  //  z-index: 1;
  //  top: 0;
  //  left: 8px;
  //  height: 100%;
  //}
  p {
    font-size: 12px;
    font-family: 'GolosText';
    color: rgba(0, 0, 0, 0.4);
    span {
      font-family: 'GolosText-Medium';
    }
  }
}
.tabs_block {
  :global(.ant-tabs-tab) {
    margin: 0 4px !important;
    font-family: 'GolosText' !important;
    border-radius: 24px !important;
    padding: 6px 14px !important;
    box-sizing: border-box;
    div {
      text-align: center;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-size: 10px !important;
      color: #252525 !important;
      font-family: 'GolosText-Medium';
      margin: 0 auto;
    }
  }
  :global(.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
    text-shadow: none !important;
    color: #fff !important;
    font-family: 'GolosText-Medium';
    font-size: 10px;
  }
  :global(.ant-tabs-nav) {
    background: #fff;
    border-radius: 50px;
    padding: 6px 16px;
    width: 210px;
    margin: 0 auto 16px;
    max-width: 100%;
  }
  :global(.ant-tabs .ant-tabs-tab.ant-tabs-tab-active) {
    background: #252525;
    border-radius: 8px;
    position: relative;
  }
  :global(.ant-tabs .ant-tabs-ink-bar) {
    background: transparent;
  }
  .block_valute {
    button {
      background: #221f1c;
      color: #fff;
    }
  }
}

.center_text {
  div {
    width: 100%;
  }
  :global(h4) {
    text-align: center;
  }
}
.info_bl_other {
  li {
    margin-bottom: 8px;
    span {
      font-size: 12px;
    }
  }
}
.info_bl {
  position: relative;
  height: calc(100vh - 180px);
  .info_bl_one {
    max-height: calc(100svh - 180px);
    overflow-y: auto;

    ul {
      padding: 0 0 0 16px;
      margin: 0;
      li {
        font-size: 14px;
        font-family: 'GolosText';
        list-style-type: disc;
        color: rgba(37, 37, 37, 0.6);
        > span {
          color: #221f1c;
          display: block;
        }
      }
    }
    .center_img {
      margin: 32px auto;
      display: block;
      max-height: 59vh;
      max-width: 80%;
    }
  }
  .info_buttons {
    position: relative;
    z-index: 16;
    display: flex;
    // bottom: 8px;
    // position: absolute;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    button {
      border-radius: 100px;
      background: #7a24ea;
      width: 40px;
      font-size: 12px;
      padding: 8px 0;
      font-family: 'GolosText-Medium';
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .link_btn {
    border-radius: 100px;
    background: #d3d3d3;
    display: block;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    color: rgba(37, 37, 37, 0.6);
    padding: 8px 0;
    font-family: 'GolosText-Medium';
  }
}
.requisites_one {
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  width: 100%;
  span {
    color: #959595;
    font-family: 'GolosText-Medium';
    font-size: 11px;
  }
  p {
    color: #221f1c;
    font-family: 'GolosText';
    font-size: 12px;
  }
}
// HISTORY

.history {
  height: calc(100svh - 44px);
  overflow: hidden;

  display: flex;
  flex-direction: column;

  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
  }

  &_title {
    font-size: 18px;
    font-family: 'GolosText-Medium';
    font-weight: normal;
    color: #221f1c;
  }

  &_list {
    flex: 1 1 auto;
    overflow: auto;
  }
}

.acc_statement {
  padding: 0 15px;
  padding-bottom: 40px;

  &_title {
    color: #221f1c;
    font-family: GolosText-Medium;
    font-size: 18px;
    font-weight: 400;
  }
}

.tabs_input {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto 16px;
  width: fit-content;

  :global(.ui-tabs-wrap) {
    column-gap: 8px;
  }

  :global(.ui-clickable) {
    padding: 10px 14px;
    white-space: nowrap;
    text-transform: uppercase;
    font-family: GolosText-Medium;
    font-size: 10px;
  }
}

.guide_link_btn {
  text-align: left;
  flex: 48.5%;
  border-radius: 12px;
  margin-bottom: 8px;
  box-sizing: border-box;
  padding: 16px;
  margin-right: 8px;
  text-decoration: none;
  max-width: 50%;
  display: block;

  &:nth-child(2n) {
    margin-right: 0;
  }
  img {
    height: 16px;
    margin-bottom: 8px;
    display: block;
  }
  span {
    display: block;
    font-size: 13px;
    line-height: 20px;
    font-family: 'GolosText';
  }
  .chip {
    background-color: #7a24ea;
    border-radius: 5px;
    color: #eaeaea;
    font-family: GolosText-Bold, sans-serif;
    font-size: 7px;
    text-transform: uppercase;
    position: absolute;
    top: 4px;
    right: 2px;
    padding: 2px;
  }
  &.halyk {
    background: #ffffff url(../../assets/images/halyk_fon.svg) no-repeat 100% 100%;
    span {
      color: #221f1c;
    }
  }
  &.centercredit {
    background: #fff url(../../assets/images/centercredit_fon.svg) no-repeat 100% 100%;
    span {
      color: #221f1c;
    }
  }
  &.centercredit {
    background: #fff url(../../assets/images/centercredit_fon.svg) no-repeat 100% 100%;
    span {
      color: #221f1c;
    }
  }
  &.jusan {
    background: #fff url(../../assets/images/jusanfon.png) no-repeat 100% 100%;
    span {
      color: #221f1c;
    }
  }
  &.forte {
    background: #fff url(../../assets/images/firte_fon.png) no-repeat 100% 100%;
    span {
      color: #221f1c;
    }
  }
  &.bereke {
    background: rgba(2, 61, 255, 0.78) url(../../assets/images/bereke_fon.svg) no-repeat 100% 100%;
    span {
      color: #fff;
    }
  }
  &.eub {
    background: #fff url(../../assets/images/euro_fon.svg) no-repeat 100% 100%;
    span {
      color: #221f1c;
    }
  }
  &.dr {
    background: #fff;
    span {
      color: #221f1c;
    }
    p {
      font-family: 'GolosText-Medium';
      font-size: 14px;
      color: #221f1c;
    }
  }
}
.list_bb {
  height: calc(100vh - 40px);
  overflow-y: auto;
}

.no_results {
  text-align: center;
  margin: 40px 0;

  p {
    color: var(--basic-dark60);
    font-family: GolosText-Medium, sans-serif;
    font-size: 14px;
  }
}

.text_timer {
  text-align: center;
  color: var(--basic-dark40);
  font-size: 12px;
  margin-bottom: 8px;
}
