.userTitle {
  text-align: center;
  margin: 12px 0 20px;
  p {
    font-family: 'GolosText-Medium', sans-serif;
    font-weight: 500;
    &:first-of-type {
      color: var(--basic-dark);
      font-size: 24px;
      line-height: 28.8px;
      margin-bottom: 8px;
    }
    &:nth-of-type(2) {
      color: #25252599;
      font-size: 16px;
    }
  }
}

.userStatus {
  padding: 4px 8px;
  border-radius: 10px;
  background: #fff8d1;
  font-family: 'GolosText-Medium';
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: fit-content;
  color: var(--basic-dark);
  :global(.main-icon) {
    margin-right: 4px;
    path {
      fill: #14d533;
    }
  }
}

.notVerified {
  text-align: center;
  p {
    color: #25252599;
    font-size: 14px;
    &:first-of-type {
      font-family: GolosText-Bold, sans-serif;
    }
    &:last-of-type {
      font-family: GolosText-Medium, sans-serif;
      margin-bottom: 20px;
    }
  }
}

.btnVerify {
  margin-top: 24px;
  font-size: 16px;
  padding: 12px 0;
}

.linksWrap {
  margin-top: 40px;
}

.linkBtn {
  padding: 8px 16px;
  background-color: var(--basic-light);
  border-radius: 8px;

  margin-top: 16px;
  &:first-child {
    margin-top: 0;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  font-family: 'GolosText-Medium';
  color: var(--basic-dark60);

  :global(.main-icon) {
    path {
      fill: var(--basic-dark40);
    }
  }

  & > div:last-child {
    display: flex;
    align-items: center;
  }

  &Inboxes {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    border-radius: 100%;
    background-color: var(--basic-accent);
    font-family: 'GolosText-Bold';
    font-size: 8.5px;
    line-height: 18px;
    text-align: center;
    color: var(--basic-light);
  }
}
.grau_bl {
  button {
    font-size: 12px;
    font-family: 'GolosText';
    text-decoration: none;
    padding: 6px 0;
    display: block;
    color: rgba(37, 37, 37, 0.6);
  }
  a {
    font-size: 12px;
    font-family: 'GolosText';
    text-decoration: none;
    padding: 6px 0;
    display: block;
    color: rgba(37, 37, 37, 0.6);
  }
}

.btnExit {
  margin-top: 16px;
  padding: 8px 16px;
  border-color: var(--basic-dark20);
  color: var(--basic-dark40);
}

.delimiter {
  margin: 16px 0;
  width: 100%;
  height: 1px;
  background-color: var(--basic-dark10);
}
