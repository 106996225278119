.wrapper {
  width: 100%;
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1000;

  >div {
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.alert {
  background-color: #959595;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 0 40px;

  >div {
    margin-right: 8px;

    div {
      display: flex;
      align-items: center;

      p {
        color: #fff;
        font-size: 12px;
        text-transform: uppercase;
        margin-left: 2px;
      }
    }
    >p {
      color: #D3D3D3;
      font-size: 10px;
      margin-top: 4px;
    }
  }
  button {
    color: #D3D3D3;
    font-size: 10px;
  }
}