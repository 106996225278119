.ui-tabs {
  position: relative;
  padding: 5px;
  border-radius: 20px;
  background-color: white;

  &-pointer {
    position: absolute;
    border-radius: 50px;
    background-color: var(--basic-dark);
  }

  &-wrap {
    display: flex;
    border-radius: 50px;
  }

  &__item {
    .ui-clickable {
      border-radius: 50px;
      padding: 10px 20px;
      color: var(--basic-dark);
      transition: 0.4s ease;
    }

    &.active {
      .ui-clickable {
        color: white;
      }
    }
  }
}
