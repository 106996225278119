.searchList {
  height: 100%;

  :global(.search-input) {
    margin-bottom: 10px;
  }
}

.searchListWrap {
  height: 40vh;
  overflow-y: auto;
}

.searchListEmpty {
  margin: 20px auto;
  width: 200px;

  svg {
    display: block;
    margin: 10px auto;
    width: 60px;
    height: 60px;

    path {
      stroke: var(--basic-dark40);
      stroke-width: 1px;
    }
  }

  h4 {
    text-align: center;
    font-weight: 500;
  }
}

.loader {
  display: block;
  margin: 20px auto;
}
