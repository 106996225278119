@import "../../scss/variables.scss";
@import "../../scss/fonts";
@import "../../scss/mixins";

$header_transition: 0.3s ease;

.fix_head {
  position: fixed;
  top: 0;
  padding: 0 16px;
  left: 0;
  width: 100%;
  max-width: 576px;
  z-index: 12;
  right: 0;
  box-sizing: border-box;
  margin: 0 auto;
  //background-color: #f5f5f5;
  background-color: #ffffff;

  transition: 0.2s ease;
  &_hidden {
    transform: translateY(-100%);
    pointer-events: none;
  }

  // :global(.TabsSymbolList) {
  //   flex-shrink: 0;
  //   width: 100%;
  //   margin-left: 0px;
  //   overflow-x: scroll;
  //   height: calc(100vh - 172px);
  // }
}

.header_block {
  display: flex;
  flex-direction: row;
  background: #f5f6f7;
  transition: 0.1s ease;
  align-items: center;
  padding: 0 16px;
  margin: 0 -16px;
  height: 48px;
  justify-content: space-between;
  &__title {
    font-size: 16px;
    font-family: "GolosText-Medium";
    text-align: center;
    width: 100%;
    display: block;
  }
  &__title2 {
    font-size: 18px;
    font-family: "GolosText-Medium";
    text-align: center;
    width: 100%;
    margin-left: 24px;
    display: block;
  }
}
.head_title {
  .header_block {
    button {
      width: 24px;
      color: $color_text_gr1;
      display: flex;
      font-size: 14px;
      font-family: "GolosText-Medium";
      font-weight: 500;
      align-items: center;
      img {
        margin-right: 8px;
      }
    }
  }
}
.header_flex {
  display: flex;
  align-items: center;
}

.header_btns {
  height: 100%;
  flex-shrink: 0;
  position: relative;
  transition: 0.4s ease;

  button {
    position: absolute;
    top: 6px;

    transition: 0.4s ease;

    &:nth-child(1) {
      top: 10px;
    }
  }
}

.header_search_close {
  font-size: 14px;
  font-weight: 500;

  color: var(--basic-accent);
  padding-bottom: 2px;
  border-bottom: 1px solid var(--basic-accent);
  border-style: dashed;
}

.header_search {
  margin-left: auto;

  position: relative;
  transition: $header_transition;
}

.header_search_wrap {
  position: relative;
  width: 100%;
  margin-right: 8px;
}

.header_search_clear {
  position: absolute;
  right: 3px;
  top: 2px;
  width: 30px;
  height: 30px;

  transition: $header_transition;

  :global(.main-icon) {
    width: 20px;
    height: 20px;
  }
  svg {
    width: 12px;
    path {
      fill: #afafaf !important;
    }
  }
}

.header_logo {
  flex-shrink: 0;
  overflow: hidden;

  transition: $header_transition;
}

.header_open_search {
  opacity: 0;
}

.button_menu {
  position: relative;
  height: 100%;
  transform: translateY(-2px);
  transition: $header_transition;

  button {
    width: 34px;
    height: 34px;

    border-color: var(--basic-dark20);
    background-color: white;
  }

  b {
    position: absolute;
    top: 6px;
    right: 0px;
    height: 18px;
    width: 18px;
    padding-top: 3px;
    pointer-events: none;
    border-radius: 50%;
    box-sizing: border-box;
    text-align: center;
    color: #fff;
    font-size: 8.5px;
    font-family: "GolosText";
    background: #753bbd;
  }
}
.back_bl {
  img {
    width: 24px !important;
  }
}
.but_search {
  line-height: 0;
  width: 170px;
  border-radius: 100px;
  border: 1px solid #eaeaea;
  display: flex;
  background: #eaeaea;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  justify-content: space-between;
  padding: 8px 10px;
  cursor: pointer;
  margin-right: 8px;

  img {
    height: 16px;
    margin-right: 2px;
  }
  span {
    font-size: 12px;
    display: block;
    font-family: "GolosText-Medium";
    color: #a8a8a8;
  }
}
.notificat_but {
  line-height: 0;
  img {
    height: 24px;
  }
}
.header_glob {
  display: flex;
  flex-direction: row;
  background: #f5f6f7;
  align-items: center;
  padding: 8px 16px;
  margin: 0 -16px;
  justify-content: space-between;
  a {
    img {
      margin: 0;
      height: 24px;
    }
  }
  @media screen and (max-width: 600px) {
    max-width: 100%;
  }
  button {
    background: transparent;
    box-shadow: none;
    height: 24px;
  }
  .back_bl {
    display: flex;
    align-items: center;
    font-family: "GolosText-Medium";
    color: #7c7c7c;
    font-size: 14px;
    img {
      margin-right: 4px;
    }
  }
}
.globus {
  margin: 0;
  border: 0;
  padding: 0;
  img {
    width: 24px !important;
  }
}
.lang_modal {
  top: 8px;
  padding-top: 16px;
  border-radius: 14px;
  :global(.header) {
    display: none !important;
  }
  :global(.ant-modal-content) {
    padding: 0;
    background: #0a0a0a;
  }
  :global(.ant-modal-body) {
    height: 250px;
    border-radius: 14px;
  }
  :global(.ant-modal-close) {
    top: -24px;
    right: 0;
    background: transparent;
  }
}

.header_no {
  :global(.header) {
    display: none;
  }
}

@media (max-width: 415px) {
  .header_block {
    .but_search {
      width: 140px;
    }
  }
}

@media (max-width: 340px) {
  .header_block {
    .but_search {
      width: 130px;
    }
  }
}