.info-block {
  border-radius: 999px;
  padding: 8px;
  background-color: #fffbe8;

  display: flex;
  align-items: center;

  &-icon {
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    border-radius: 100%;

    background-color: #fff8d1;

    display: flex;
    align-items: center;
    justify-content: center;

    .main-icon {
      width: 32px;
      height: 32px;

      path {
        fill: var(--misk-alert);
      }
    }
  }

  &-text {
    padding: 10px 0;
    margin-left: 10px;
  }
}
