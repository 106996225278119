.ticker-paper-card {

    .ticker-card-wrap {
        padding: 10px 20px;
    }

    &-bottom {
        margin-top: 8px;
        text-align: left;

        font-size: 13px;

        b {
            font-family: 'GolosText-Medium';
            font-weight: 500;
        }
        i {
            font-style: normal;
            color: var(--basic-dark40);
        }
    }
}