.screen {
  width: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  :global(.tabs-symbol-wrap) {
    position: relative;
    height: calc(100% - 46px);
  }

  :global(.TabsSymbolList) {
    &:first-child {
      margin-left: 0px !important;
    }
    margin-left: 16px !important;
  }
}

.recent {
  margin-bottom: 20px;

  &Top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    path {
      fill: var(--basic-dark40) !important;
    }
  }

  h4 {
    font-weight: 500;
  }
}

.recentList {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 5px;
}

.tickers_list {
  margin-top: 10px;
  flex: 1;
  overflow-y: auto;
  padding-bottom: 50px;
}

.tabs {
  margin-top: 10px;
}

.tickerChip {
  padding: 5px 8px;
  width: fit-content;

  font-family: 'GolosText-Medium';
  background-color: var(--basic-light2);
  color: var(--basic-dark60);
  border-radius: 50px;
}
