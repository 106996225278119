.resendBlock {
    margin-top: 8px;
    min-height: 30px;
    text-align: center;
}

.title {
    font-size: 18px;
    font-weight: 400;
    font-family: 'GolosText-Medium';
    color: var(--basic-dark);
    margin-bottom: 8px;
}