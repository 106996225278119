.ticker-order-card {
  .ticker-card-wrap {
    padding: 10px 20px;
  }
  .ticker-card-price {
    text-align: right;

    p {
      &:first-child {
        font-size: 12px;
        color: var(--basic-dark40);
      }
      &:nth-child(2), &:nth-child(3) {
        font-family: "GolosText-Medium";
      }
    }

    p + p {
      margin-top: 5px;
    }
  }
}
