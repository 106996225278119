.filterBtn {
  margin-left: auto;
}

.filter {
  padding: 0 15px;
  padding-bottom: 24px;

  &Block {
    margin-top: 20px;

    p {
      margin-bottom: 10px;
      font-size: 15px;
      color: var(--basic-dark);
      font-family: 'GolosText-Medium';
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &InputBlock {
    display: flex;
    column-gap: 15px;
    :global(.ant-picker) {
      flex: 1;
    }
  }
}

.filterChipWrap {
  background-color: var(--secondary-light);

  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 5px;

  padding-bottom: 15px;
}
.filterChip {
  display: flex;
  align-items: center;

  padding: 2px 10px;
  padding-right: 0;
  background-color: var(--basic-light2);
  border-radius: 50px;

  :global(.ui-icon-button) {
    margin-left: 5px;
  }

  & > span {
    color: var(--basic-dark60);
    font-size: 12px;
    font-weight: 500;
  }
}

.inputNumber {
  padding: 5px 15px;

  font-size: 14px;
  color: var(--basic-dark);

  font-size: 14px;
  border: 1px solid var(--basic-dark20) !important;
  border-radius: 50px;
  appearance: none;
  box-shadow: 0 0 0 3px rgba($color: #000000, $alpha: 0) !important;
  transition: 0.4s ease;

  &:hover,
  &:active,
  &:focus {
    border-color: var(--basic-dark) !important;
  }

  &:focus,
  .ant-picker-focused {
    box-shadow: 0 0 0 3px rgba($color: #000000, $alpha: 0.1) !important;
  }
}

.datePeriod {
  display: flex;
  column-gap: 5px;
  margin-bottom: 10px;

  :global(.ui-button) {
    white-space: nowrap;
    font-size: 13px;
    padding: 8px 0;
  }
}
