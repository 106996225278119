@import "../../scss/variables.scss";
@import "../../scss/mixins";

.ticker_item {
  border-radius: 20px;
  background-color: #fff;
  border-left: 0px;
  border-top: 0;
  border-right: 0px;
  /*box-shadow: inset 0px -1px 1px #4a4466;
  border-bottom: 1.8px solid #4a4466;*/
  padding: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  &.ticker_item2 {
    border-top: 0;
    border-left: 0;
    position: relative;
    border-right: 0;
    margin-bottom: 16px;
    cursor: pointer;
    border-radius: 8px;
    background: linear-gradient(
      180deg,
      rgba(142, 124, 197, 0.13) 0.39%,
      rgba(188, 166, 255, 0) 126.06%
    );
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      padding: 1px;
      border-radius: 8px;
      background: linear-gradient(
        180deg,
        rgba(188, 166, 255, 0.4),
        rgba(15, 236, 240, 0.4)
      );
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    .ticker_item__date {
      display: flex;
      align-items: center;
    }
  }

  &__text {
    margin-left: 16px;
    width: calc(100% - 56px);

    p {
      font-size: 16px;
      color: $white_text;
      font-family: "GolosText-Medium";
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      color: $gray_text;
      font-size: 14px;
      text-transform: none;
    }
  }

  &__pr {
    p {
      font-size: 16px;
      color: $white_text;
    }

    span {
      font-size: 16px;
      text-align: right;
      color: $white_text;
      display: block;
    }
  }

  &__date {
    display: inline-flex;
    align-items: center;

    img {
      width: 32px;
    }

    & .image-preview {
      border: 1px solid $border_logo;
    }
  }
  &__delete {
    flex: 0 0 50px;
    display: flex;
    align-items: center;
    button {
      padding: 0;
      margin: -16px auto;
      display: block;
    }
    img {
      width: 18px;
    }
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 1px;
    border-radius: 8px;
    background: linear-gradient(
      -180deg,
      rgba(188, 166, 255, 0.28),
      rgba(232, 225, 251, 0.1)
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}
.block_gr {
  //width: 60px;
  padding: 0 5px;
}
.block_pr {
  text-align: right;
  width: 60px;
  span {
    width: 19px;
    height: 19px;
    text-align: center;
    border-radius: 50%;
  }
}
.width_50 {
  width: calc(100% - 50px);
}

.collection_my {
  margin-bottom: 16px;
  .del_icon {
    position: absolute;
    right: -4px;
    z-index: 1;
    cursor: pointer;
    top: 0;
  }
  .ticker_item {
    padding: 10px;
    position: relative;
    display: block;
    .ticker_item__date {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    .ticker_item__text {
      width: auto;
      text-align: right;
      p {
        display: block;
        font-size: 16px;
      }
      span {
        font-size: 10px;
        color: rgba(255, 255, 255, 0.4);
        display: block;
      }
    }
    .name {
      display: block;
      width: 100%;
      font-size: 12px;
      color: #000;
      font-family: "GolosText-Medium";
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 8px;
    }
  }
}

.new_width {
  width: calc(100% - 24px);
}

.new_fon {
  background: linear-gradient(
    180deg,
    rgba(0, 255, 255, 0.04) 0%,
    rgba(20, 21, 25, 0) 100%
  );

  backdrop-filter: blur(25px);
}
.flex_bb {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .flex_bb {
    justify-content: flex-start;
    :global(.image-preview) {
      margin-right: 16px;
    }
    .name {
      margin: 0;
      font-size: 14px;
      padding: 0;
      color: rgba(255, 255, 255, 0.6);
    }
    span {
      font-size: 10px;
      margin: 0;
      padding: 0;
      color: rgba(255, 255, 255, 0.35);
      i {
        color: rgba(255, 255, 255, 0.7);
        font-style: normal;
      }
    }
  }
  .ticker_item__text {
    p {
      font-size: 12px !important;
    }
  }
}
