@import 'fonts';
@import 'variables';

:-internal-direct-focus {
  outline-color: transparent;
  outline-style: auto;
  outline-width: 0;
}
input:-internal-autofill-selected {
  background-color: #fff !important;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  user-select: none;
}

* {
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar {
  height: 12px;
  width: 2px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 5px;
  /*border: 3px solid orange;*/
}
$primary-color: transparent;

.body_padding {
  .container {
    padding: 50px 16px 120px 16px;
  }
}
body {
  background: #f5f5f5;
  color: #a8a8a8;
  scrollbar-color: $background_site;
  margin: 0;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, 'GolosText', sans-serif !important;
  line-height: 1.4715;
  font-size: 14px;
  background-attachment: fixed !important;

  &.ant-scrolling-effect {
    width: 100% !important;
    position: fixed;
    overflow: auto !important;
  }
  .container {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 576px !important;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 16px 16px 16px;
    box-sizing: border-box;
    background: transparent;
  }
  .container2 {
    position: relative;
    width: 100%;
    max-width: 544px !important;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 0px 60px 0px !important;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    background: transparent;
  }
  .container3 {
    position: relative;
    width: 100%;
    max-width: 544px !important;
    margin-right: auto;
    margin-left: auto;
    padding: 0px 0px 124px 0px !important;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    background: transparent;
  }
  .container4 {
    position: relative;
    width: 100%;
    max-width: 544px !important;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 0px 48px 0px !important;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    background: transparent;
    min-height: 900px;
  }
  .container5 {
    position: relative;
    width: 100%;
    max-width: 544px !important;
    margin-right: auto;
    margin-left: auto;
    padding: 0px 0px 48px 0px !important;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    background: transparent;
    min-height: 900px;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

#root {
  font-family: -apple-system, BlinkMacSystemFont, 'GolosText', sans-serif !important;
  font-weight: 400;
  height: inherit;
  background: #f5f5f5;
  .ant-col,
  .ant-tabs,
  .ant-layout,
  .ant-spin,
  .ant-carousel,
  .ant-spin-container,
  .ant-checkbox-wrapper,
  .ant-btn {
    font-family: 'GolosText', sans-serif !important;
  }
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #252525 !important;
  background-color: #d3d3d3 !important;
}
.ant-radio:hover .ant-radio-inner {
  border-color: #252525 !important;
}
.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #252525 !important;
}
.ant-tabs-nav::before {
  border: none !important;
}
.ant-checkbox > div:first-child {
  filter: grayscale(1);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: var(--basic-dark);
}

:global(.ant-wave) {
  --wave-color: var(--basic-accent) !important;
}

body.modalOpen {
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
  height: 100vh;
  top: 0;
  bottom: 0;
  // -webkit-overflow-scrolling: touch;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input,
textarea {
  font-size: 16px;
  border-radius: 0px;
  &:hover,
  &:focus {
    outline: none;
    box-shadow: none !important;
  }
}
input,
textarea,
button {
  font-family: 'GolosText';
}
button {
  background: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
}
p {
  margin: 0;
  padding: 0;
}
a:focus,
a:hover {
  outline: none;
  text-decoration: none;
}
.fontsize12 {
  font-size: 12px !important;
}
.height_all {
  height: 100svh;
  padding: 0;
  vertical-align: middle;
  box-sizing: border-box;
  margin: 0;
  overflow: scroll;
}

.p-16 {
  padding: 16px;
}
.p-8 {
  padding: 8px;
}
.pr-16 {
  padding-right: 16px;
}
.pr-8 {
  padding-right: 8px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pb-64 {
  padding-bottom: 114px;
}
.pb-56 {
  padding-bottom: 56px;
}
.pt-16 {
  padding-top: 16px;
}
.pt-0 {
  padding-top: 0px !important;
}
.pt-8 {
  padding-top: 8px !important;
}
.pb-32 {
  padding-bottom: 300px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pl-0 {
  padding-left: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.pb-8 {
  padding-bottom: 8px;
}
.pl-8 {
  padding-left: 8px;
}
.pl-16 {
  padding-left: 16px;
}

.m-0 {
  margin: 0 !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.mt-8 {
  margin-top: 8px !important;
}
.mt-32 {
  margin-top: 32px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-32 {
  margin-bottom: 32px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.mb-4 {
  margin-bottom: 4px !important;
}
.mb-56 {
  margin-bottom: 56px !important;
}
.mt-60 {
  margin-top: 60px;
}
.mt-62 {
  margin-top: 96px;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.ml-8 {
  margin-left: 8px;
}
.b-0 {
  border: 0 !important;
}
.minus {
  color: $red !important;
  span {
    color: $red !important;
  }
}
.minus_bg {
  background: $red !important;
}
.plus {
  color: $green !important;
  span {
    color: $green !important;
  }
}
.plus_bg {
  background: $green !important;
}
p.loader,
span.loader {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  color: transparent;
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.buttonc {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  button {
    display: flex;
    font-size: 14px;
    position: absolute;
    align-items: center;
    right: 16px;
    justify-content: space-between;
    color: #727272;
    span {
      border-bottom: 1px dashed;
    }
    img {
      margin-left: 8px;
    }
    &:first-child {
      left: 16px;

      img {
        transform: rotate(180deg);
        margin-right: 8px;
        margin-left: 0;
      }
    }
  }
}

.normal_input_col {
  border: 1px solid $color_text_b !important;
  border-radius: 50px !important;
  color: $color_text_b !important;
  padding: 8px 16px !important;
  height: auto !important;
  font-size: 16px !important;
}
.normal_input_search_col {
  border: 1px solid $color_text_b !important;
  border-radius: 50px !important;
  color: $color_text_b !important;
  padding: 4px 8px 3px !important;
  height: auto !important;
  font-size: 16px !important;
  .ant-input {
    outline: none !important;
    box-shadow: none !important;
    border: 0 !important;
    border-inline-end-width: 0;
    list-style: none;
  }
  .ant-input:active,
  .ant-input:hover,
  .ant-input:focus {
    outline: 0;
    outline-offset: 0;
  }
  .ant-input-affix-wrapper {
    border-width: 0 !important;
  }
  .ant-input-group-addon button {
    border: 0;
  }
}
.but_more_news {
  margin-bottom: 16px;
  display: block;
  margin: 0 auto;
  margin-left: auto;

  border-style: dashed !important;
  padding-bottom: 2px !important;
  color: var(--basic-dark40) !important;
  border-color: var(--basic-dark40) !important;
}
.css-1u9h2vk {
  margin: 0 !important;
}
.css-4b375e section {
  transition: all 0.5s ease-in-out 0s !important;
}

.ant-checkbox-inner {
  &::after {
    border-color: #252525;
  }
}

.ant-checkbox-wrapper {
  span {
    color: $color_text_gr1;
  }
  .ant-checkbox-inner {
    border: 1px solid #252525;
    background: #d3d3d3 !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    &::after {
      border-color: #252525;
    }
  }
  &:hover {
    .ant-checkbox-inner {
      border: 1px solid #252525;
      background-color: #d3d3d3 !important;
    }
  }
}

.basic-routes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;
  overflow-y: auto;
  z-index: 10;

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: black;*/
    background-color: #e4e2e2;
    z-index: 99;
    pointer-events: none;
  }
}

.modal--active {
  .overlay-routes-page,
  .basic-routes {
    overflow: hidden !important;
  }
}
.Bouncefix {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.overlay-routes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;
  height: -webkit-fill-available;
  overflow: hidden;
  z-index: 10;

  &-page {
    position: absolute;
    width: calc(100% - 32px);
    padding: 0 16px;
    // padding-top: 60px;
    height: 100%;
    overflow-y: auto;
    background-color: #f5f6f7;
  }
}

.basic-routes { transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1); }
.basic-routes-overlay { transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1); }
.overlay-routes-page { transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1); }

.shimmer {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: -1px;
    width: calc(100% + 3px);
    height: 100%;
    border-radius: inherit;

    background: #eee;
    background: linear-gradient(
      110deg,
      rgba(236, 236, 236, 1) 8%,
      rgba(245, 245, 245, 1) 18%,
      rgba(236, 236, 236, 1) 33%
    );
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;

    @keyframes shine {
      to {
        background-position-x: -200%;
      }
    }

    // animation: shimmer-animation 3s infinite linear;
    // background: linear-gradient(to right, #dee0e2 4%, #efefef 25%, #dee0e2 36%);
    // background-size: 1000px 100%;

    // @keyframes shimmer-animation {
    //   0% {
    //     background-position: -1000px 0;
    //   }
    //   100% {
    //     background-position: 1000px 0;
    //   }
    // }

    transition: 0.3s ease-in-out;
    opacity: 0;
    pointer-events: none;
  }

  &-type_block {
    &::after {
      border-radius: 20px;
    }
  }

  &-type_text {
    width: fit-content;

    &.shimmer--loading {
      color: transparent !important;
      & > * {
        opacity: 0;
      }
    }

    &::after {
      border-radius: 16px !important;
      transform-origin: right;
      transform: scaleY(1) scaleX(0);
    }
  }

  &-type_logo {
    &::after {
      transform-origin: center;
    }
  }

  &--loading {
    &::after {
      transform: scaleY(1) scaleX(1) !important;
      opacity: 1;
    }
  }
}
/*
.height100 {
  height: 100%;
}
*/

.ant-picker {
  padding: 5px 15px;

  font-size: 14px;
  color: var(--basic-dark40);

  font-size: 14px;
  border: 1px solid var(--basic-dark20) !important;
  border-radius: 50px;
  appearance: none;
  box-shadow: 0 0 0 3px rgba($color: #000000, $alpha: 0) !important;
  transition: 0.4s ease;

  &:hover,
  &:active,
  &:focus {
    border-color: var(--basic-dark) !important;
  }

  &:focus,
  .ant-picker-focused {
    box-shadow: 0 0 0 3px rgba($color: #000000, $alpha: 0.1) !important;
  }
}
.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background-color: var(--basic-dark) !important;
  }
}
.ant-picker-cell-today {
  .ant-picker-cell-inner {
    background: var(--basic-dark10);

    &::before {
      border: none !important;
    }
  }
}
.ant-picker-today-btn {
  color: var(--basic-dark) !important;
}
.ant-picker-header-view button {
  font-family: 'GolosText-Medium';
  font-weight: 500 !important;
}


.aom-fadeOut {
  animation: fadeOut 0.5s ease 1;
  @keyframes fadeOut {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.highlightEffect {
  animation: highlightEffect 1s ease 1;
  overflow: hidden;

  @keyframes highlightEffect {
    from {
      opacity: 0;
      max-height: 0;
      padding: 0 16px;
      box-shadow: 0px 0px 30px 0px rgba(122, 36, 234, 0.4);
    }
    to {
      opacity: 1;
      max-height: 200px;
      padding: 12px 16px;
      box-shadow: 0px 0px 0px 0px rgba(122, 36, 234, 0);
    }
  }
}